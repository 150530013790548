import {subDays} from 'date-fns';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {floorHour} from '@/util/date-utils';
import {JSX} from 'react';
import {getI18n} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Styled from 'styled-components';

import {HealthReportNoRecording} from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {noop} from '@/util/noop';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component: JSX.ElementType) {
    return Page({
        name: () => getI18n().t('healthReport.trends.title') ?? 'Health Report - trends 90 nights',
        pagePath: getPagePath,
    })(Component);
}

type HealthReportTrendsPageProps = {
    params?: {id_patient: string},
    className?: string;
};

function HealthReportTrendsPageUnstyled(props: HealthReportTrendsPageProps) {
    const navigate = useNavigate();
    const patientId = props.params?.id_patient ?? '';

    const [patientDataLoading, patientData] = usePatientData({
        patientId: patientId,
        memoize: true,
    });

    const [signalsEventConfigurationLoading, signalsEventConfiguration, /*err*/, refresh] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: patientId } },
    });

    const loading = (
        (patientDataLoading || !patientData) ||
        (signalsEventConfigurationLoading || !signalsEventConfiguration)
    );

    if (loading) {
        return (
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.TRENDS}
                eventConfiguration={signalsEventConfiguration}
                onChanged={noop}
            >
                <LoadingView />
            </HealthReportWrapper>
        );
    }

    let dateFrom = null;
    let dateTo = null;
    let dashboardHasContent = false;
    let lastRecordingDate = null;
    if (patientData.lastRecordingDate) {
        dateFrom = floorHour(subDays(NewDateUtil(), 89));
        dateTo = floorHour(NewDateUtil());

        lastRecordingDate = patientData.lastRecordingDate;

        dashboardHasContent = formatApiDateToJSDate(patientData.lastRecordingDate).getTime() > dateFrom.getTime();
    }

    const handleWidgetChartClick = (selectedDate: Date) => {
        const url = `/patients/${patientId}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };

    return (
        <div className={`health-report-trends ${props.className ?? ''}`}>
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.TRENDS}
                eventConfiguration={signalsEventConfiguration}
                onChanged={refresh}
            >
                {!dashboardHasContent ? (
                    <HealthReportNoRecording patient={patientData} activeDashboard={HealthReportDashboardTypeEnum.TRENDS} />
                ) : (
                    <div className={'health-report-content'}>
                        <div className="health-report-widgets">
                            <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    lastRecordingDate={lastRecordingDate!}
                                    dateFrom={formatJSDateToApiDateTs(dateFrom!)}
                                    dateTo={formatJSDateToApiDateTs(dateTo!)}
                                    displayPreviousNightsAverage={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>
                            <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    lastRecordingDate={lastRecordingDate!}
                                    dateFrom={formatJSDateToApiDateTs(dateFrom!)}
                                    dateTo={formatJSDateToApiDateTs(dateTo!)}
                                    displayPreviousNightsAverage={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>
                        </div>
                    </div>
                )}
            </HealthReportWrapper>
        </div>
    );
}

//language=SCSS
let HealthReportTrendsPage = Styled(HealthReportTrendsPageUnstyled)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;

        .health-report-widgets {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            width: 100%;
        }

        .widget-average {
            margin-bottom: 10px;
            position: relative;
        }
    }
}
`;

// @ts-expect-error this is correct
HealthReportTrendsPage = withRouter(wrapWithPage(HealthReportTrendsPage));

export {HealthReportTrendsPage};
