import {MessageType} from '@protobuf-ts/runtime';
import type {ServiceInfo} from '@protobuf-ts/runtime-rpc';
import {useEffect, useState, useSyncExternalStore} from 'react';

import {buildBackendCallKey, grpcRequest, GrpcRequestFn, ServiceClientConstructor} from '@/services/api-requests/grpc-request-ts';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiCache} from '@/services/backend-api-cache';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {NoExtraProp} from '@/types/no-extra-prop';
import {singlePromise} from '@/util/promise-util';

export type useBackendQueryArgs<ServiceClient extends ServiceInfo, Request extends object, Response extends object, TransformedResponse extends Response> = {
    serviceClient: ServiceClientConstructor<ServiceClient>;
    query: GrpcRequestFn<Request, Response>;
    data: Request | MessageType<Request>;
    memoize?: boolean; // if the data has already been fetched, use the memoized value instead of making a new request
    noRefresh?: boolean; // fetch the data once, and do not refresh it when the refresh listeners are invalidated
    disabled?: boolean; // do not make the request at all
    transformer?: useBackendQueryTransformer<Response, TransformedResponse>;
};
export type useBackendQueryResult<Response extends object, TransformedResponse extends Response = Response> = [
    boolean, // loading state
    TransformedResponse | null, // response
    Error | null, // error
    () => void, // refresh function
];
export type useBackendQueryTransformer<Response extends object, TransformedResponse extends Response> =
    (response: Response) => TransformedResponse;

export function useBackendQuery<ServiceClient extends ServiceInfo, Request extends object, Response extends object, TransformedResponse extends Response>
(options: NoExtraProp<useBackendQueryArgs<ServiceClient, Request, Response, TransformedResponse>>, dependencies?: unknown[]) : useBackendQueryResult<Response, TransformedResponse> {
    const {disabled, memoize, data} = options;
    const backendCallKeyPrefix = buildBackendCallKey(options.serviceClient, options.query);

    const dataStr = data && (data as MessageType<Request>).toJsonString ?
        (data as MessageType<Request>).toJsonString(data as Request) :
        JSON.stringify(data);
    const backendCallKey = `${backendCallKeyPrefix}/${dataStr}`;
    dependencies = dependencies ?? [];

    const cachedResponse = memoize ? BackendApiCache.get(backendCallKey) : null;
    const hasMemoizedData = !disabled && memoize && cachedResponse;
    const transformer: (response: Response) => TransformedResponse | null = (response: Response) => {
        if (!response) {
            return null;
        }
        if (options.transformer) {
            return options.transformer(response);
        }
        return response as TransformedResponse;
    };

    const [loading, setLoading] = useState<boolean>(!hasMemoizedData);
    const [result, setResult] = useState<TransformedResponse | null>(transformer(cachedResponse?.responseData as Response ?? null));
    const [error, setError] = useState<Error | null>(null);
    const [refreshCounter, setRefreshCounter] = useState<number>(0);

    function refresh() {
        BackendApiCache.clear(backendCallKey); // no need to check for memoize. If the key is not there, it does not matter
        setRefreshCounter(refreshCounter+1); // trigger a re-fetch
    }

    // Refresh listener
    const refreshListener = options.noRefresh ? BackendApiRefreshListeners.get('noRefresh') : BackendApiRefreshListeners.get(backendCallKeyPrefix);
    const listenerCounter = useSyncExternalStore<number>(
        refreshListener.onChange,
        () => refreshListener.value,
    );

    useEffect(() => {
        if (disabled) {
            setLoading(false);
            return;
        }
        setLoading(!hasMemoizedData);
        setResult(transformer(cachedResponse?.responseData as Response ?? null));
        setError(null);

        fetchData(backendCallKey, options)
            .then((response: Response) => {
                setResult(transformer(response));
                setError(null);
            })
            .catch(error => {
                console.error(error);
                setResult(null);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ ...dependencies, refreshCounter, listenerCounter, backendCallKey, disabled ]);

    return [
        loading,
        result,
        error,
        refresh,
    ];
}

async function fetchData<ServiceClient extends ServiceInfo, Request extends object, Response extends object, TransformedResponse extends Response>
(backendCallKey: string, {serviceClient, query, data, memoize}: useBackendQueryArgs<ServiceClient, Request, Response, TransformedResponse>) {
    console.debug('backendCallKey', backendCallKey);

    if (memoize) {
        const cachedResponse = BackendApiCache.get(backendCallKey);
        if (cachedResponse) {
            console.debug(`useBackendQuery: returned memoized ${backendCallKey}`, cachedResponse.responseData);
            return cachedResponse.responseData as Response;
        }
    }

    const accessToken = await Auth0Service.getAccessToken();
    const out = await singlePromise(() => {
        return grpcRequest<ServiceClient, Request, Response>(
            accessToken ?? '',
            serviceClient,
            query,
            data as Request
        );
    }, backendCallKey);

    if (memoize) {
        BackendApiCache.put(backendCallKey, out.response);
    }

    return out.response;
}
