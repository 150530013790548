import { FullDate } from 'gabi-api-ts/v2/patient/common/patient_common';
import {useTranslation} from 'react-i18next';

import {
    apiDateToday,
    formatApiDateTimeUniversal,
    formatApiDateTimeWeekday,
    getDaysDifference,
} from '@/util/apidate-util';

function getPatientLastRecordingViewModel(lastRecordingDataTime?: FullDate) {
    const today = apiDateToday();
    const lastRecordingDate = lastRecordingDataTime?.date ?? {
        year: 2020,
        month: 1,
        day: 1,
    };
    const lastRecordingTime = lastRecordingDataTime?.time ?? {
        hour: 0,
        minutes: 0,
        seconds: 0,
    };
    const daysOld = getDaysDifference(lastRecordingDate, today);
    const isMoreThan7DaysOld = daysOld > 7;
    return {
        daysOld: daysOld,
        isMoreThan7DaysOld: isMoreThan7DaysOld,
        formattedDateTime: formatApiDateTimeUniversal(lastRecordingDate, lastRecordingTime),
        formattedDateTimeWithWeekday: formatApiDateTimeWeekday(lastRecordingDate, lastRecordingTime),
    };
}
export const testables = {
    getPatientLastRecordingViewModel,
};

type PatientLastRecording = {
    lastRecordingDataTime?: FullDate,
};
const PatientLastRecording = ({lastRecordingDataTime}: PatientLastRecording) => {
    const { t } = useTranslation();
    const viewModel = getPatientLastRecordingViewModel(lastRecordingDataTime);

    if (viewModel.isMoreThan7DaysOld) {
        return (
            <em>
                {t('patient.noRecordingFor', 'No recording for')} {viewModel.daysOld} {t('global.days', 'days')}
            </em>
        );
    }
    else {
        return (
            <div title={viewModel.formattedDateTime}>
                {viewModel.formattedDateTimeWithWeekday}
            </div>
        );
    }
};

export {PatientLastRecording};
