import {Trend} from 'gabi-api-ts/v2/signal/query/signal_query';

import {RecordingInformationTrend} from '@/enum/recording-information-trend';
import warningDownIcon from '@/public/icons/warning-down-icon.svg';
import warningIcon from '@/public/icons/warning-icon-white.svg';
import warningUpIcon from '@/public/icons/warning-up-icon.svg';

type HealthReportStatisticsTrendProps = {
    trend: Trend;
};

function HealthReportStatisticsTrend({trend}: HealthReportStatisticsTrendProps) {
    switch (trend) {
    case RecordingInformationTrend.NO_DATA:
        return <img src={warningIcon} className="warningFlag" alt="Warning - no data" />;
    case RecordingInformationTrend.LOW:
        return <img src={warningDownIcon} className="warningFlag" alt="Warning - low"/>;
    case RecordingInformationTrend.HIGH:
        return <img src={warningUpIcon} className="warningFlag" alt="Warning - high"/>;
    case RecordingInformationTrend.NORMAL:
        return null;
    }
}

export {HealthReportStatisticsTrend};
