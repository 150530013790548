import {useAuth0} from '@auth0/auth0-react';
import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';

import {MainTopbarFull} from '@/components/layout/main-topbar-full';
import LoadingView from '@/components/static/loading-view';
import SmallScreenView from '@/components/static/small-screen-view';
import {FeedbackAlerts} from '@/components/widgets/feedback-alerts';
import config from '@/config';
import useWindowSize from '@/hooks/use-window-size';
import {FirestoreTransportService} from '@/services/api-transport/firestore-transport-service';
import {MockTransportService} from '@/services/api-transport/mock-transport-service';
import {Auth0Service} from '@/services/auth0-service';
import {BackendApiService} from '@/services/backend-api-service';
import {useDebugFlags} from '@/services/debug-service';
import {TutorialService} from '@/services/tutorial-service';
import '@/style';
import {noop} from '@/util/noop';
import {LoginUnauthorizedView} from '@/views/login-unauthorized-view';
import {LoginView} from '@/views/login-view';

import '@/stylesheets/recharts-overrides.scss';
import '@/stylesheets/react-datepicker-overrides.scss';

// Display Gabi Environment and define Transport Service
console.log(`Gabi environment: "${__GABI_ENV__}"`);

if(__GABI_ENV__ === 'testing')  {
    BackendApiService.setTransportService(new MockTransportService());
} else {
    BackendApiService.setTransportService(new FirestoreTransportService());
}

// Root component, including common components surrounding active route
export default function RootAnalytics() {
    const [backendLoading, setBackendLoading] = useState(true);
    const { user, isAuthenticated, isAuth0Loading, error, getAccessTokenSilently } = useAuth0();
    const [windowWidth, windowHeight] = useWindowSize();
    const debugFlags = useDebugFlags();
    
    useEffect(() => {
        console.log('setting access token provider');
        Auth0Service.setAccessTokenProvider(getAccessTokenSilently);
        TutorialService.init().finally(() => setBackendLoading(false));
    }, [getAccessTokenSilently]);

    const sendConnectionNotification = async () => {
        try {
            const accessToken = await Auth0Service.getAccessToken();
            const response = await fetch(config.cloudFunction.hcpConnectionNotification, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'x-api-key': config.firebase.apiKey,
                    'caller-id': 'analytics-web',
                    'caller-version': `${__APP_VERSION__}-${__COMMIT_HASH__}`,
                },
            });
            if (!response.ok) {
                console.error(`sendConnectionNotification: ${response.status}`);
            }
        }
        catch (error) {
            console.error('sendConnectionNotification: error with the fetch operation:', error);
        }
    };

    if (debugFlags.allowSmallScreen || (windowWidth >= 1024 && windowHeight > 600)) {
        if (isAuth0Loading || backendLoading) {
            return <LoadingView/>;
        }
        else if (isAuthenticated) {
            if (!user) {
                throw new Error('undefined user');
            }
            else if (error) {
                throw new Error(`Auth0 error: ${error}`);
            }
            else {
                sendConnectionNotification().then(noop);
            }

            return (
                <div>
                    <MainTopbarFull/>

                    <main className="main-content">
                        <Outlet/>
                    </main>

                    <FeedbackAlerts/>
                </div>
            );
        }
        else {
            const urlParams = new URLSearchParams(window.location.search);
            const entries = urlParams.entries();
            const params = {};
            for (const entry of entries) {
                params[entry[0]] = entry[1];
            }
            if (params['error'] === 'access_denied' && params['error_description']) {
                return <LoginUnauthorizedView errorMessage={params['error_description']} />;
            }
            else {
                return (
                    <div>
                        <div>
                            <LoginView />
                            <FeedbackAlerts/>
                        </div>
                    </div>
                );
            }
        }
    }
    else {
        return <SmallScreenView />;
    }
}
