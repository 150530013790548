import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {PatientChildForm} from '@/components/business/common/patient/patient-child-form';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {BackendApiService} from '@/services/backend-api-service';
import {FeedbackAlertsService, FeedbackAlertType} from '@/services/feedback-alerts-service';

class PatientUpdateChild extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object.isRequired,
        onChanged: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <h3>{t('updateChild.title') ?? 'Child information'}</h3>

                <PatientChildForm patient={props.patient} loading={state.loading} onChanged={this.handleFormChanged} onSubmit={(patient) => this.updatePatientChild(patient)} />
            </div>
        );
    }

    handleFormChanged(notSavedDataChild) {
        if(this.props.onChanged) {
            this.props.onChanged(notSavedDataChild);
        }
    }

    async updatePatientChild(patient) {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'patient',
            modelName: 'updatePatient',
            data: {
                patient: patient
            }
        }).then((patient) => {
            BackendApiRefreshListeners.refreshByCall(PatientQueryServiceClient, PatientQueryServiceClient.prototype.get);
            FeedbackAlertsService.add(FeedbackAlertType.SUCCESS, 'updatePatient', this.props.t('updateChild.updated') ?? 'Patient updated!');
            this.setState(state => ({
                ...state,
                loading: false,
            }));
            return patient;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }
}

export default withTranslation()(PatientUpdateChild);

