import {subDays} from 'date-fns';
import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {AlertType, HasData, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {floorHour} from '@/util/date-utils';
import {useTranslation} from 'react-i18next';

import {HealthReportPercentagePerDay} from '@/components/business/analytics/health-report/health-report-percentage-per-day';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {NoDataCell} from '@/components/business/analytics/patient-overview-cells/no-data-cell';
import {PatientsActiveTableCellHoverable} from '@/components/business/analytics/patients/patients-active-table-cell-hoverable';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {MiniLoadingView} from '@/components/static/mini-loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformEventsSummary} from '@/model/transformers/transform-events-summary';
import {useDebugFlags} from '@/services/debug-service';
import {colorPalette} from '@/themes/darkmode';
import {NewDateUtil} from '@/util/new-date-util';
import {roundTo} from '@/util/number-util';
import {getSignalEventConfig, getSignalTypeIdentifier, getSignalTypeUnit} from '@/util/signal-type-util';

type PulseAverageCellProps = {
    alertType: AlertType;
    patient: Patient;
    signalType: SignalType;
    unit: string;
    handleWidgetChartClick: (selectedDate: Date) => void;
}

function EventsCell({alertType, patient, signalType, unit, handleWidgetChartClick}: PulseAverageCellProps) {
    const { t } = useTranslation();
    const debugFlags = useDebugFlags();
    const [eventsSummaryLoading, eventsSummary] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalEventsSummary,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: patient.patientId,
            date: patient.lastRecordingDate,
            alertType: alertType,
            nbPreviousDays: 14,
        },
        transformer: transformEventsSummary,
    });

    const [signalsEventConfigurationLoading, signalsEventConfiguration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: patient.patientId! },
    });

    const loading = (
        (eventsSummaryLoading || !eventsSummary) ||
        (signalsEventConfigurationLoading || !signalsEventConfiguration)
    );

    if (loading) {
        return (
            <PatientsActiveTableCellHoverable
                hasHover={false}
                hoverElement={() => null}
            >
                <div className="text-center">
                    <MiniLoadingView />
                </div>
            </PatientsActiveTableCellHoverable>
        );
    }
    else {
        const signalEventConfiguration: SignalEventConfiguration = signalsEventConfigurationLoading ? {
            low: { value: -1000 },
            high: { value: -1000 },
            sensibility: { seconds: 3600 },
        } : getSignalEventConfig(signalsEventConfiguration!.configuration!, signalType);

        const today = NewDateUtil();
        const lastRecordingDate = new Date(
            patient.lastRecordingDate!.year,
            patient.lastRecordingDate!.month-1,
            patient.lastRecordingDate!.day
        );
        return (
            <PatientsActiveTableCellHoverable
                hasHover={debugFlags.alwaysShowTrendsOverlays || (eventsSummary.hasEvents && !eventsSummary.hasNormalTrend)}
                hoverElement={() => (
                    <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                        <ErrorBoundary>
                            <ColoredContainer className="widget-events" color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}>
                                {!eventsSummary.hasTrend && (
                                    <p className="trendsNoData">{t('pages.patients.trends.noData') ?? 'There are no available data in the previous 14 nights.'}</p>
                                )}
                                {eventsSummary.hasTrend && (
                                    <HealthReportPercentagePerDay
                                        patientId={patient.patientId!.id}
                                        signalType={signalType}
                                        alertType={alertType}
                                        lastRecordingDate={lastRecordingDate}
                                        dateFrom={floorHour(subDays(lastRecordingDate, 14))}
                                        dateTo={floorHour(today)}
                                        ignoreUnifiedDuration
                                        onClick={handleWidgetChartClick}
                                        displayPreviousNightsAverage={true}
                                    />
                                )}
                            </ColoredContainer>
                        </ErrorBoundary>
                    </div>
                )}
            >
                {(eventsSummary.events?.hasData !== HasData.HAS_MIN_RELIABLE_DATA) || isNaN(eventsSummary.events?.value) ? (
                    <NoDataCell/>
                ) : (<>
                    <span className="events-cell__nb-events">
                        <strong>{roundTo(eventsSummary.events?.value, 2)}</strong> {unit}
                    </span>
                    {alertType === AlertType.Alert_LOW && (
                        <span>{t('global.below') ?? 'Below'} {signalEventConfiguration.low!.value}{getSignalTypeUnit(signalType)}</span>
                    )}
                    {alertType === AlertType.Alert_HIGH && (
                        <span>{t('global.above') ?? 'Above'} {signalEventConfiguration.high!.value}{getSignalTypeUnit(signalType)}</span>
                    )}

                    {eventsSummary.trend !== undefined && (
                        <HealthReportStatisticsTrend
                            trend={eventsSummary.trend}
                        />
                    )}
                </>)}
            </PatientsActiveTableCellHoverable>
        );
    }
}

export {EventsCell};
