import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {AlertType, EventsForHour, HasData, SignalType, Trend} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {TFunction} from 'i18next';
import React, {JSX} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {CategoricalChartState} from 'recharts/types/chart/types';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickHourTwoDigits} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-two-digits';
import {getDomainForEvents} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartDualLabel, ChartDualLabelProps} from '@/components/charts/chart-dual-label';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformEventsSummary} from '@/model/transformers/transform-events-summary';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {formatApiDateUniversal} from '@/util/apidate-util';
import {getSignalTypeIdentifier, getSignalTypeUnit} from '@/util/signal-type-util';
import {formatHourTwoDigits} from '@/util/time-utils';

type HealthReportEventsPerHourProps = {
    patientId: string,
    signalType: SignalType,
    alertTypes: AlertType[];
    date: Date,
    subtitle: string | React.JSX.Element,
    signalEventConfiguration: SignalEventConfiguration;
    onClick?: (data: EventPerHourMerged) => void,
};

export type EventPerHourMerged = EventsForHour & {
    above: number,
    below: number,
};

function HealthReportEventsPerHour({patientId, signalType, alertTypes, date, subtitle, signalEventConfiguration, onClick}: HealthReportEventsPerHourProps) {
    const { t } = useTranslation();

    const [signalEventsSummaryAboveLoading, signalEventsSummaryAbove] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalEventsSummary,
        memoize: true,
        disabled: !alertTypes.includes(AlertType.Alert_HIGH),
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            date: formatJSDateToApiDateTs(date),
            alertType: AlertType.Alert_HIGH,
            nbPreviousDays: 14,
        },
        transformer: transformEventsSummary,
    });
    const [signalEventsSummaryBelowLoading, signalEventsSummaryBelow] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalEventsSummary,
        memoize: true,
        disabled: !alertTypes.includes(AlertType.Alert_LOW),
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            date: formatJSDateToApiDateTs(date),
            alertType: AlertType.Alert_LOW,
            nbPreviousDays: 14,
        },
        transformer: transformEventsSummary,
    });
    const [eventsPerHourAboveLoading, eventsPerHourAbove] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventsPerHour,
        memoize: true,
        disabled: !alertTypes.includes(AlertType.Alert_HIGH),
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            date: formatJSDateToApiDateTs(date),
            alertType: AlertType.Alert_HIGH,
        },
    });
    const [eventsPerHourBelowLoading, eventsPerHourBelow] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventsPerHour,
        memoize: true,
        disabled: !alertTypes.includes(AlertType.Alert_LOW),
        data: {
            signalType: signalType,
            patientId: { id: patientId },
            date: formatJSDateToApiDateTs(date),
            alertType: AlertType.Alert_LOW,
        },
    });

    const title = (() => {
        switch(signalType) {
        case SignalType.Signal_PR: return (t('healthReport.events.last24h.pulseRateTitle') ?? 'Pulse Rate - events');
        case SignalType.Signal_SPO2: return (t('healthReport.events.last24h.spo2Title') ?? 'SpO2 - percentage');
        default: return ''; // not used
        }
    })();
    const signalTypeIdentifier = getSignalTypeIdentifier(signalType);

    const loading = (
        (signalEventsSummaryAboveLoading) ||
        (signalEventsSummaryBelowLoading) ||
        (eventsPerHourAboveLoading) ||
        (eventsPerHourBelowLoading)
    );

    if (loading) {
        return (
            <ChartContainer
                title={title}
                subtitle={subtitle}
            >
                <LoadingView color={colorPalette.signalType[signalTypeIdentifier]} />
            </ChartContainer>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const renderTooltip = (data: TooltipProps<any, any>, t: TFunction) => {
        const unit = signalType === SignalType.Signal_SPO2 ? '%' : ` ${t('global.events') ?? 'events'}`;
        if (data?.payload) {
            const renderValue = (payload: {hasRecording: boolean, reliableDurationInSeconds: number}, dataAvailableRender: () => JSX.Element) => {
                if (!payload || !(payload.hasRecording)) {
                    return (
                        <span>{t('global.noDataAvailable')}</span>
                    );
                }
                else if (payload.reliableDurationInSeconds === 0) {
                    return (
                        <span>{t('global.noReliableDataAvailable')}</span>
                    );
                }
                else {
                    return dataAvailableRender();
                }
            };

            if (alertTypes.length > 1) {
                const eventsAbove = data?.payload[0]?.payload?.above;
                const eventsBelow = Math.abs(data?.payload[0]?.payload?.below);

                const date = data?.payload[0]?.payload?.time?.date;
                const hour = data?.payload[0]?.payload?.hour;

                return (
                    <div className="chart-tooltip">
                        <strong>
                            {date && formatApiDateUniversal(date)}
                            {date && ' - '}
                            {hour && formatHourTwoDigits(hour)}
                        </strong><br />
                        {renderValue(data?.payload[0]?.payload, () => {
                            return (<>
                                <span>{t('global.above') ?? 'Above'}{': '}{eventsAbove}{unit}</span>
                                <span>{t('global.below') ?? 'Below'}{': '}{eventsBelow}{unit}</span>
                            </>);
                        })}
                    </div>
                );
            }
            else {
                const events = data?.payload[0]?.payload?.value;

                const date = data?.payload[0]?.payload?.time?.date;
                const hour = data?.payload[0]?.payload?.hour;

                return (
                    <div className="chart-tooltip">
                        <strong>
                            {date && formatApiDateUniversal(date)}
                            {date && ' - '}
                            {hour && formatHourTwoDigits(hour)}
                        </strong><br />
                        {renderValue(data?.payload[0]?.payload, () => {
                            return (<>
                                <span>{events}{unit}</span>
                            </>);
                        })}
                    </div>
                );
            }
        }
        return null;
    };

    if (patientId && date && signalEventConfiguration) {
        // Merge events per hour above and below as one array
        const eventsPerHour: EventPerHourMerged[] = [];
        for (let i=0; i < 24; ++i) {
            const baseEvent = (eventsPerHourAbove?.eventsPerHour[i] || eventsPerHourBelow?.eventsPerHour[i]) as EventsForHour;
            eventsPerHour[i] = {
                ...baseEvent,
                value: (eventsPerHourAbove?.eventsPerHour[i]?.value ?? 0) + (eventsPerHourBelow?.eventsPerHour[i]?.value ?? 0),
                above: eventsPerHourAbove?.eventsPerHour[i]?.value ?? 0,
                below: -1 * (eventsPerHourBelow?.eventsPerHour[i]?.value ?? 0),
            };
        }

        // Define domain by taking the min/max amount of event per hour for symetry
        let maxAmount = 9;
        for (let i=0; i < 24; ++i) {
            if (eventsPerHour[i].above > maxAmount) {
                maxAmount = eventsPerHour[i].above;
            }
            if (-1 * eventsPerHour[i].below > maxAmount) {
                maxAmount = -1 * eventsPerHour[i].below;
            }
        }

        const domainForEvents = getDomainForEvents(maxAmount, alertTypes.length > 1);

        const eventsPerHourNotZero = eventsPerHour.filter(x => x.above !== 0 || x.below !== 0).length;
        const hasDataForDayAbove = signalEventsSummaryAbove?.events?.hasData ?? HasData.HAS_NO_DATA;
        const hasDataForDayBelow = signalEventsSummaryBelow?.events?.hasData ?? HasData.HAS_NO_DATA;
        const bestHasDataForDay: HasData = Math.max(hasDataForDayAbove, hasDataForDayBelow);

        const averageUnit = (() => {
            switch(signalType) {
            case SignalType.Signal_PR: return <span>{' '}{t('global.eventsPerHour') ?? 'events/h'}</span>;
            case SignalType.Signal_SPO2: return '%';
            default: return ''; // no unit
            }
        })();

        const signalEventsSummaryToDisplay = alertTypes[0] === AlertType.Alert_LOW ? signalEventsSummaryBelow : signalEventsSummaryAbove;

        const topRightContent = (
            <div className="average-block">
                <p>
                    <HealthReportStatisticsTrend
                        trend={signalEventsSummaryToDisplay?.trend ?? Trend.NORMAL}
                    />
                    {t('healthReport.aggregatedSignal.avgRecording') ?? 'Last night avg:'}{' '}
                    <AverageValue averageObj={signalEventsSummaryToDisplay?.events ?? null} unit={averageUnit} precision={2} />
                </p>
                <p>
                    {t('healthReport.aggregatedSignal.avgLast14Nights') ?? 'Prev. 14 nights avg:'}{' '}
                    <AverageValue averageObj={signalEventsSummaryToDisplay?.eventsPrevious ?? null} unit={averageUnit} precision={2} />
                </p>
            </div>
        );

        const tooltipText = <>
            <p>
                {signalType === SignalType.Signal_PR &&
                    <>{t('infoButton.events.pulseRate') ?? 'This chart shows the number of events per hour for this night.'}&nbsp;</>
                }
                {signalType === SignalType.Signal_SPO2 &&
                    <>{t('infoButton.events.spo2') ?? 'This chart shows the percentage of time for which the SpO2 measurements are outside the defined thresholds.'}&nbsp;</>
                }
                {t('infoButton.events.reliableData') ?? 'Notice that only reliable data are considered.'}
            </p>
        </>;

        const handleClick: CategoricalChartFunc = (data: CategoricalChartState) => {
            if (onClick) {
                if (data.activePayload && data.activePayload[0] && data.activePayload[0].payload) {
                    onClick(data.activePayload[0].payload as EventPerHourMerged);
                }
            }
        };

        return (
            <StyledWrapper className="health-report-events-per-hour" color={colorPalette.signalTypeLight[signalTypeIdentifier]}>
                <ChartContainer
                    title={title}
                    subtitle={subtitle}
                    topRightContent={alertTypes.length === 1 ? topRightContent : undefined}
                    infoTooltipTitle={`${t(`global.${signalTypeIdentifier}`)} - ${t('infoButton.events.title') ?? 'events'}`}
                    infoTooltipText={tooltipText}
                    infoTooltipOverlayPosition="bottomLeft"
                >
                    {bestHasDataForDay < HasData.HAS_MIN_RELIABLE_DATA ? (
                        <div className="empty-message">
                            {(bestHasDataForDay === HasData.HAS_NO_DATA) && (
                                t('healthReport.events.last24h.noRecording')
                            )}
                            {(bestHasDataForDay === HasData.HAS_DATA) && (
                                t('healthReport.events.last24h.noReliableData')
                            )}
                            {(bestHasDataForDay === HasData.HAS_RELIABLE_DATA) && (
                                t('healthReport.events.last24h.notEnoughReliableData')
                            )}
                        </div>
                    ) : (eventsPerHourNotZero <= 0) ? (
                        <div className="empty-message">
                            {t('healthReport.events.last24h.noEvent')}
                        </div>
                    ) : (alertTypes.length > 1) ? (
                        <BarChart data={eventsPerHour} margin={{ top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClick} stackOffset="sign">
                            <XAxis
                                dataKey="hour" interval={3}
                                tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />}
                            />
                            <YAxis
                                width={46} dataKey="value"
                                domain={domainForEvents.domain}
                                ticks={domainForEvents.ticks}
                                interval={0}
                                tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[signalTypeIdentifier]} />}
                                label={({viewBox}: ChartDualLabelProps) => (
                                    <ChartDualLabel
                                        viewBox={viewBox}
                                        fill={colorPalette.signalTypeLight[signalTypeIdentifier]}
                                        aboveText={`${t('global.above') ?? 'Above'} ${signalEventConfiguration.high!.value}${getSignalTypeUnit(signalType)}`}
                                        belowText={`${t('global.below') ?? 'Below'} ${signalEventConfiguration.low!.value }${getSignalTypeUnit(signalType)}`}
                                    />
                                )}
                            />
                            <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false} />
                            <Bar
                                dataKey="below"
                                stackId="stack"
                                fill={colorPalette.signalTypeLight[signalTypeIdentifier]}
                                minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : -1}
                            />
                            <Bar
                                dataKey="above"
                                stackId="stack"
                                fill={colorPalette.signalType[signalTypeIdentifier]}
                                minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : 1}
                            />
                            <ReferenceLine
                                y={0}
                                stroke={'rgba(255,255,255, 0.2)'}
                            />
                        </BarChart>
                    ) : (
                        <BarChart height={250} data={eventsPerHour} margin={{ top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClick}>
                            <XAxis dataKey="hour" interval={3} tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />} />
                            <YAxis
                                width={46}
                                domain={domainForEvents.domain}
                                ticks={domainForEvents.ticks}
                                interval={0}
                                dataKey="value"
                                tick={(props) => <ChartAxisTickColored {...props} alignUpTick fill={colorPalette.signalTypeLight[signalTypeIdentifier]} />}
                                allowDecimals={false}
                            />
                            <Tooltip content={(data) => renderTooltip(data, t)} isAnimationActive={false} />
                            <Bar
                                dataKey="value"
                                fill={colorPalette.signalType[signalTypeIdentifier]}
                                minPointSize={(_, index) => eventsPerHour[index].reliableDurationInSeconds <= 0 ? 0 : 1}
                            />
                        </BarChart>
                    )}
                </ChartContainer>
            </StyledWrapper>
        );
    }
    else {
        return (
            <ComponentErrorMessage component="HealthReportEventsPerHour" />
        );
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    min-height: 260px;
    height: 100%;
    
    .chart-tooltip span {
        color: ${props => props.color};
    }
    
    .empty-message {
        margin-top: 25px;
    }

    svg {
        cursor: pointer;
    }
    
    .average-block {
        position: relative;
        font-size: 13px;

        p {
            margin-bottom: 5px;
            text-align: right;
            white-space: nowrap;
        }

        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportEventsPerHour};
