import {AlertType, Date as ApiDate, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {JSX} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Styled from 'styled-components';

import GuidedTourHealthReport from '@/components/business/analytics/guidedTour/guided-tour-health-report';
import {FormattedAggregatedSignalDataBlock,HealthReportAggregatedSignal} from '@/components/business/analytics/health-report/health-report-aggregated-signal';
import {HealthReportDuration} from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsList} from '@/components/business/analytics/health-report/health-report-events-list';
import {EventPerHourMerged, HealthReportEventsPerHour} from '@/components/business/analytics/health-report/health-report-events-per-hour';
import {HealthReportNoRecording} from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {useBackendQuery} from '@/hooks/use-backend-query';
import timelineIcon from '@/public/icons/timeline-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {useTutorialStatus} from '@/services/tutorial-service';
import {colorPalette, colors, medias} from '@/themes/darkmode';
import {apiDateToday, isAfter, subDays} from '@/util/apidate-util';
import {noop} from '@/util/noop';
import {getSignalTypeUnit} from '@/util/signal-type-util';
import {formatApiDateForUrl} from '@/util/url-util';

function wrapWithPage(Component: JSX.ElementType) {
    return Page({
        name: () => getI18n().t('healthReport.lastnight.title') ?? 'Health Report - last night',
        pagePath: () => ([
            {
                route: 'patients',
                name: 'Patients'
            },
        ]),
    })(Component);
}

type HealthReportLastAvailableNightPageProps = {
    params: {
        id_patient: string;
    },
    className: string,
};

function HealthReportLastAvailableNightPageUnstyled(props: HealthReportLastAvailableNightPageProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params: Readonly<Partial<{
        id_patient: string;
    }>> = useParams();
    const patientId = params.id_patient ?? '';

    const tutorialStatus = useTutorialStatus();

    const [signalsEventConfigurationLoading, eventConfiguration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: patientId } },
    });

    const [patientDataLoading, patientData] = usePatientData({
        patientId: patientId,
        memoize: true,
    });

    function handleAggregatedSignalChartClick(data: FormattedAggregatedSignalDataBlock) {
        const selectedHour = data.at.time;
        const url = `/patients/${patientId}/timeline/${data.at.date}/${selectedHour}`;
        navigate(url);
    }

    function handleEventsPerHourChartClick(data: EventPerHourMerged) {
        const selectedHour = (data.hour < 10) ? `0${data.hour}:00` : `${data.hour}:00`;
        const url = `/patients/${patientId}/timeline/${formatApiDateForUrl(data.time?.date ?? { year: 2020, month: 1, day: 1 })}/${selectedHour}`;
        navigate(url);
    }

    let dateFrom: ApiDate = { year: 2020, month: 1, day: 1 };
    let dateTo: ApiDate = { year: 2020, month: 1, day: 1 };
    let dashboardHasContent = false;
    let timelineDate = null;
    if (patientData && patientData.lastRecordingDate) {
        dateFrom = patientData.lastRecordingDate;
        dateTo = patientData.lastRecordingDate;
        timelineDate = formatApiDateForUrl(patientData.lastRecordingDate);
        const lastRecordingDateLimit = subDays(apiDateToday(), 30);
        dashboardHasContent = isAfter(dateFrom, lastRecordingDateLimit);
    }

    // We load the events list here so we know wheter to show the events list block or not
    const [ eventsListLoading, eventsList, /*eventsListError*/ ] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getTypedEventsList,
        memoize: true,
        disabled: !dashboardHasContent || patientDataLoading || !patientData,
        data: {
            patientId: {id: patientId},
            date: dateFrom,
        }
    }, [eventConfiguration]);

    const loading = (
        (signalsEventConfigurationLoading || !eventConfiguration) ||
        (dashboardHasContent && (eventsListLoading || !eventsList)) ||
        (patientDataLoading || !patientData)
    );

    if (loading) {
        return (
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.LAST_24H}
                eventConfiguration={eventConfiguration}
                onChanged={noop}
            >
                <LoadingView />
            </HealthReportWrapper>
        );
    }

    const hasEvents = (eventsList?.event?.length ?? 0) > 0;

    return (
        <div className={`${props.className} ${hasEvents ? 'has-events' : ''}`}>
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={HealthReportDashboardTypeEnum.LAST_24H}
                eventConfiguration={eventConfiguration}
                onChanged={noop}
            >
                {tutorialStatus.guidedTourHealthReport && (
                    <GuidedTourHealthReport />
                )}

                {dashboardHasContent && (
                    <div className="health-report-switch-wrapper">
                        <HealthReportDuration patientId={patientId} dateFrom={dateFrom} dateTo={dateTo}/>
                        <Link
                            to={`/patients/${patientId}/timeline/${timelineDate}`}
                            id="button-timeline"
                            className="button-link"
                        >
                            {t('healthReportDashboardNavigation.dailyBiometrics') ?? 'Daily biometrics'}
                        </Link>
                    </div>
                )}

                {(dashboardHasContent && eventConfiguration) && (
                    <div className={'health-report-content health-report-content-view'}>
                        <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.pulseRate}>
                            <HealthReportAggregatedSignal
                                patientId={patientId}
                                signalType={SignalType.Signal_PR}
                                date={formatApiDateToJSDate(dateFrom)}
                                signalEventConfiguration={eventConfiguration.configuration!.hr!}
                                onClick={handleAggregatedSignalChartClick}
                            />
                        </ColoredContainer>

                        <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                            <HealthReportEventsPerHour
                                patientId={patientId}
                                signalType={SignalType.Signal_PR}
                                alertTypes={[ AlertType.Alert_HIGH, AlertType.Alert_LOW ]}
                                date={formatApiDateToJSDate(dateFrom)}
                                subtitle={<span>
                                    {t('healthReport.events.last24h.subtitle', {duration: eventConfiguration.configuration?.hr?.sensibility?.seconds}) ?? '#events/h with aduration of X seconds'}
                                </span>}
                                signalEventConfiguration={eventConfiguration.configuration!.hr!}
                                onClick={handleEventsPerHourChartClick}
                            />
                        </ColoredContainer>

                        <ColoredContainer className="widget-aggregated-signal" color={colorPalette.signalType.spo2}>
                            <HealthReportAggregatedSignal
                                patientId={patientId}
                                signalType={SignalType.Signal_SPO2}
                                date={formatApiDateToJSDate(dateFrom)}
                                signalEventConfiguration={eventConfiguration.configuration!.spo2!}
                                onClick={handleAggregatedSignalChartClick}
                            />
                        </ColoredContainer>

                        <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                            <HealthReportEventsPerHour
                                patientId={patientId}
                                signalType={SignalType.Signal_SPO2}
                                alertTypes={[ AlertType.Alert_LOW ]}
                                date={formatApiDateToJSDate(dateFrom)}
                                subtitle={`
                                    ${t('global.below') ?? 'Below'} ${eventConfiguration.configuration?.spo2?.low?.value}${getSignalTypeUnit(SignalType.Signal_SPO2)}
                                `}
                                signalEventConfiguration={eventConfiguration.configuration!.spo2!}
                                onClick={handleEventsPerHourChartClick}
                            />
                        </ColoredContainer>

                        {(hasEvents && eventsList) && (
                            <HealthReportEventsList
                                patientId={patientId}
                                eventsList={eventsList}
                                eventConfiguration={eventConfiguration}
                            />
                        )}
                    </div>
                )}
                {!dashboardHasContent && (
                    <HealthReportNoRecording patient={patientData} activeDashboard={HealthReportDashboardTypeEnum.LAST_24H} />
                )}
            </HealthReportWrapper>
        </div>
    );
}

//language=SCSS
const HealthReportLastAvailableNightPage = Styled(wrapWithPage(HealthReportLastAvailableNightPageUnstyled))`
& {
    .health-report-switch-wrapper {
        position:relative;
        margin-bottom:20px;
        #button-timeline {
            background: ${colors.buttonCreate.background} url(${timelineIcon}) no-repeat 15px center;
            background-size: 25px;
            position: absolute;
            right: 0;
            top: 0;
            padding: 10px 15px 10px 50px;
            border-radius: 5px;
            &:hover, &:focus {
                color: #fff;
                background-color: ${colors.buttonCreate.backgroundHover};
            }
        }
    }
    
    .health-report-content {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        @media screen and (${medias.xlMin}) {
            &.has-events {
                grid-template-columns: minmax(0, 2fr) minmax(250px, 1fr) minmax(260px, 1fr);
            }
        }
        
        grid-auto-rows: 340px;
        flex-wrap: nowrap;
        gap: 10px;
    }

    .health-report-events-list {
        display: none;
        @media screen and (${medias.xlMin}) {
            display: block;
        }
        grid-column: 3;
        grid-row: 1;
        grid-row-end: span 2;
    }
    
    .widget-aggregated-signal {
        grid-column: 1;
    }
    
    .widget-events {
        grid-column: 2;
    }
}
`;

export {HealthReportLastAvailableNightPage};
