import { SignalAverageSummary, Trend, HasData } from 'gabi-api-ts/v2/signal/query/signal_query';

export type SignalAverageSummaryTransformed = SignalAverageSummary & {
    hasData: HasData;
    hasAverageValue: boolean;
    hasTrend: boolean;
    hasNormalTrend: boolean;
    trend: Trend;
};

export function transformAverageSummary(averageSummary: SignalAverageSummary): SignalAverageSummaryTransformed {
    const hasData = Math.min(
        averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA,
        averageSummary.averagePrevious?.hasData ?? HasData.HAS_NO_DATA,
    );
    const hasTrend = (
        (averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
        (averageSummary.averagePrevious?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
        hasData !== HasData.HAS_NO_DATA && averageSummary.trend !== Trend.NO_DATA
    );
    const trend = hasTrend ? averageSummary.trend : Trend.NO_DATA;
    return {
        ...averageSummary,
        hasData: hasData,
        hasAverageValue: (
            (averageSummary.averageValue?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
            !isNaN(averageSummary.averageValue?.value as number)
        ),
        hasTrend: hasTrend,
        hasNormalTrend: trend === Trend.NORMAL,
        trend: trend,
    };
}
