import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import { Days, Date as ApiDate } from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';

import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import {useBackendQuery, useBackendQueryResult} from '@/hooks/use-backend-query';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils-ts';
import {NoExtraProp} from '@/types/no-extra-prop';
import {apiDateToday} from '@/util/apidate-util';
import {NewDateUtil} from '@/util/new-date-util';

export type useDaysWithSignalDataArgs = {
    patient?: Patient;
    disabled?: boolean;
};

function useDaysWithSignalData(options: NoExtraProp<useDaysWithSignalDataArgs>, dependencies?: unknown[]) : useBackendQueryResult<Days> {
    const isDemoPatient = options.patient?.patientId?.id === 'demo';
    const out = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getDaysWithSignalData,
        memoize: true,
        disabled: isDemoPatient || options.disabled || (!options.patient),
        data: {
            patientId: options.patient?.patientId,
            from: options.patient?.birthDate,
            to: apiDateToday(),
        },
    }, [ ...(dependencies ?? []), options.disabled, options.patient ] );

    if (isDemoPatient) {
        return [
            false,
            { day: generateDateList(DemoPatient.patient.birthDate!) },
            null,
            out[3],
        ];
    }
    else {
        return out;
    }
}

/**
 * Generates a list of ApiDate from startDate to today. Used for the demo patient
 */
function generateDateList(startDate: ApiDate): ApiDate[] {
    const dateList: ApiDate[] = [];
    const today = NewDateUtil();
    const current = formatApiDateToJSDate(startDate);

    while (current <= today) {
        dateList.push({
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate()
        });
        current.setDate(current.getDate() + 1);
    }

    return dateList;
}

export { useDaysWithSignalData };
