import {Patients} from 'gabi-api-ts/v2/patient/query/patient_query';

import {PatientTransformed, transformPatient} from '@/model/transformers/transform-patient';

export type PatientsTransformed = Omit<Patients, 'patient'> & {
    patient: PatientTransformed[];
};

export function transformPatients(patients: Patients): PatientsTransformed {
    return {
        patient: patients.patient.map(transformPatient),
    };
}
