import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useTranslation} from 'react-i18next';

import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {NoDataCell} from '@/components/business/analytics/patient-overview-cells/no-data-cell';
import {PatientsActiveTableCellHoverable} from '@/components/business/analytics/patients/patients-active-table-cell-hoverable';
import ErrorBoundary from '@/components/errors/error-boundary';
import ColoredContainer from '@/components/layout/colored-container';
import {MiniLoadingView} from '@/components/static/mini-loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformAverageSummary} from '@/model/transformers/transform-average-summary';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {useDebugFlags} from '@/services/debug-service';
import {colorPalette} from '@/themes/darkmode';
import {subDays} from '@/util/apidate-util';
import {NewDateUtil} from '@/util/new-date-util';
import {getSignalTypeUnit, getSignalTypeIdentifier} from '@/util/signal-type-util';

type PulseAverageCellProps = {
    patient: Patient;
    signalType: SignalType;
    handleWidgetChartClick: (selectedDate: Date) => void;
}

function AverageCell({patient, signalType, handleWidgetChartClick}: PulseAverageCellProps) {
    const { t } = useTranslation();
    const debugFlags = useDebugFlags();
    const [averageSummaryLoading, averageSummary] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalAverageSummary,
        memoize: true,
        data: {
            signalType: signalType,
            patientId: patient.patientId,
            date: patient.lastRecordingDate,
            nbPreviousDays: 14,
        },
        transformer: transformAverageSummary,
    });

    if (averageSummaryLoading || !averageSummary) {
        return (
            <PatientsActiveTableCellHoverable
                hasHover={false}
                hoverElement={() => null}
            >
                <div className="text-center">
                    <MiniLoadingView />
                </div>
            </PatientsActiveTableCellHoverable>
        );
    }
    else {
        const today = NewDateUtil();
        return (
            <PatientsActiveTableCellHoverable
                hasHover={debugFlags.alwaysShowTrendsOverlays || (averageSummary.hasAverageValue && !averageSummary.hasNormalTrend)}
                hoverElement={() => (
                    <div className={'warning-overlay animate__animated animate__fadeIn animate__faster'}>
                        <ErrorBoundary>
                            <ColoredContainer color={colorPalette.signalType[getSignalTypeIdentifier(signalType)]}>
                                {!averageSummary.hasTrend && (
                                    <p className="trendsNoData">{t('pages.patients.trends.noData')}</p>
                                )}
                                {averageSummary.hasTrend && (
                                    <HealthReportStatisticsPerDay
                                        patientId={patient.patientId!.id}
                                        signalType={signalType}
                                        lastRecordingDate={patient.lastRecordingDate!}
                                        dateFrom={subDays(patient.lastRecordingDate!, 14)}
                                        dateTo={formatJSDateToApiDateTs(today)}
                                        ignoreUnifiedDuration
                                        displayPreviousNightsAverage={true}
                                        onClick={handleWidgetChartClick}
                                    />
                                )}
                            </ColoredContainer>
                        </ErrorBoundary>
                    </div>
                )}
            >
                {(!averageSummary.hasAverageValue || !averageSummary.averageValue) ? (
                    <NoDataCell/>
                ) : (<>
                    <strong>{Math.round(averageSummary.averageValue?.value ?? 0)}</strong>
                    {' '}
                    {getSignalTypeUnit(signalType)}
                    {averageSummary.trend !== undefined && (
                        <HealthReportStatisticsTrend
                            trend={averageSummary.trend}
                        />
                    )}
                </>)}
            </PatientsActiveTableCellHoverable>
        );
    }
}

export {AverageCell};
