import {formatFullTime, formatTime} from '@/util/date-utils';

type TimelineTickProps = {
    baseDate: Date;
    displaySeconds?: boolean;
    x: number;
    y: number;
    payload: {
        value: number;
    };
};

function TimelineTick({baseDate, displaySeconds, x, y, payload}: TimelineTickProps) {
    const time = baseDate.getTime() + payload.value*1000;
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={14} textAnchor={'end'} fill="#fff" >
                {displaySeconds ? formatFullTime(time) : formatTime(time)}
            </text>
        </g>
    );
}

export type {TimelineTickProps};
export {TimelineTick};
