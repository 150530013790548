import { SignalEventsSummary, Trend, HasData } from 'gabi-api-ts/v2/signal/query/signal_query';

export type SignalEventsSummaryTransformed = SignalEventsSummary & {
    hasData: HasData;
    hasEvents: boolean;
    hasTrend: boolean;
    hasNormalTrend: boolean;
    trend: Trend;
};

export function transformEventsSummary(eventsSummary: SignalEventsSummary): SignalEventsSummaryTransformed {
    const hasData = Math.min(
        eventsSummary.events?.hasData ?? HasData.HAS_NO_DATA,
        eventsSummary.eventsPrevious?.hasData ?? HasData.HAS_NO_DATA,
    );
    const hasTrend = (
        (eventsSummary.events?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
        (eventsSummary.eventsPrevious?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
        hasData !== HasData.HAS_NO_DATA && eventsSummary.trend !== Trend.NO_DATA
    );
    const trend = hasTrend ? eventsSummary.trend : Trend.NO_DATA;
    return {
        ...eventsSummary,
        hasData: hasData,
        hasEvents: (
            (eventsSummary.events?.hasData ?? HasData.HAS_NO_DATA) >= HasData.HAS_MIN_RELIABLE_DATA &&
            !isNaN(eventsSummary.events?.value as number)
        ),
        hasTrend: hasTrend,
        hasNormalTrend: trend === Trend.NORMAL,
        trend: trend,
    };
}
