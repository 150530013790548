import {Date as ApiDate} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {PatientRecordingDuration} from '@/components/business/analytics/patients/patient-recording-duration';
import IconTooltip from '@/components/buttons/icon-tooltip';
import {useBackendQuery} from '@/hooks/use-backend-query';
import warningIcon from '@/public/icons/warning-icon.svg';
import {colorPalette} from '@/themes/darkmode';
import {addDays, apiDateToday, isBefore, subDays} from '@/util/apidate-util';
import {formatApiDateUniversal} from '@/util/apidate-util';

type HealthReportDurationProps = {
    patientId: string,
    dateFrom: ApiDate,
    dateTo: ApiDate,
};

const HealthReportDuration = ({patientId, dateFrom, dateTo}: HealthReportDurationProps) => {
    const { t } = useTranslation();

    const sevenDaysAgo = subDays(apiDateToday(), 7);
    const formattedDateFrom = formatApiDateUniversal(dateFrom);
    const formattedDateTo = (formatApiDateUniversal(dateFrom) === formatApiDateUniversal(dateTo)) ? formatApiDateUniversal(addDays(dateTo, 1)) : formatApiDateUniversal(dateTo);

    const isSameDate = formatApiDateUniversal(dateFrom) === formatApiDateUniversal(dateTo);

    const [, recordingDuration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getRecordingDuration,
        memoize: true,
        disabled: !isSameDate,
        data: {
            patientId: {id: patientId},
            date: dateFrom,
        },
    });

    return (
        <StyledWrapper className="health-report-duration">
            <span>
                <strong>{t('healthReportDuration.title') ?? 'Recording period'}</strong>&nbsp;&nbsp;
            </span>
            <span>{formattedDateFrom} - {formattedDateTo}</span>
            {isBefore(dateTo, sevenDaysAgo) &&
                <div className="oldDataTooltip">
                    <IconTooltip icon={warningIcon} iconAltText={t('healthReportDuration.oldDataImgAltText') ?? 'Red warning icon'}>
                        {t('healthReportDuration.oldDataText') ?? 'Be aware that the displayed data are older than 7 days.'}
                    </IconTooltip>
                </div>
            }
            {isSameDate &&
                <span>
                    <strong>{t('healthReportDuration.duration') ?? 'Duration'}</strong>&nbsp;&nbsp;
                    {(recordingDuration) && (
                        <PatientRecordingDuration duration={recordingDuration.durationInSeconds} />
                    )}
                </span>
            }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    background: ${colorPalette.secondBackground};
    border-radius: 5px;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: ${colorPalette.activeColor};
    padding: 10px 0 10px 15px;
    display: flex;
    height: 42px;
    
    span {
        display: inline-block;
    }
    
    div.oldDataTooltip {
        display: inline-block;
        margin-left: 10px;
        margin-top: -4px;
        button {
            margin: 0;
        }
    }
    
    span:last-child {
        border-left: 1px solid ${colorPalette.darkColor};
        padding-left: 10px;
        margin-left: 10px;
        display: flex;
    }
}
`;

export {HealthReportDuration};
