import {subDays} from 'date-fns';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import styled from 'styled-components';

import {PatientsInactiveTable} from '@/components/business/analytics/patients/patients-inactive-table';
import LoadingView from '@/components/static/loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformPatients} from '@/model/transformers/transform-patients';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {NewDateUtil} from '@/util/new-date-util';

function PatientsInactive() {
    const today = NewDateUtil();
    const todayMinus7 = subDays(today, 8);
    const todayMinus90 = subDays(today, 89);

    const [patientsWithoutRecordingLoading, patientsWithoutRecording] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatientsWithoutRecording,
        data: {
            creationFrom: formatJSDateToApiDateTs(todayMinus90),
            creationTo: formatJSDateToApiDateTs(today),
        },
        memoize: false,
        noRefresh: true,
        transformer: transformPatients,
    });
    const [patientsWithRecordingLoading, patientsWithRecording] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatientsWithRecording,
        data: {
            from: formatJSDateToApiDateTs(todayMinus90),
            to: formatJSDateToApiDateTs(todayMinus7),
        },
        memoize: false,
        noRefresh: true,
        transformer: transformPatients,
    });

    const loading = (
        (patientsWithoutRecordingLoading || !patientsWithoutRecording) ||
        (patientsWithRecordingLoading || !patientsWithRecording)
    );

    if (!loading) {
        const inactivePatients = [
            ...(patientsWithoutRecording?.patient ?? []),
            ...(patientsWithRecording?.patient ?? []),
        ];

        inactivePatients.sort((a, b) => {
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
        });
        return (
            <StyledWrapper>
                <PatientsInactiveTable patients={inactivePatients} />
            </StyledWrapper>
        );
    }
    else {
        return (
            <LoadingView />
        );
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    padding-bottom: 50px;
}
`;

export {PatientsInactive};
