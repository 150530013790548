import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {SignalType, StringDate, AggregatedSignalDataBlock} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {useTranslation} from 'react-i18next';
import {Line, LineChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {CategoricalChartFunc} from 'recharts/types/chart/generateCategoricalChart';
import {CategoricalChartState} from 'recharts/types/chart/types';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import styled from 'styled-components';

import {AverageValue} from '@/components/business/analytics/health-report/health-report-average-value';
import {HealthReportStatisticsTrend} from '@/components/business/analytics/health-report/health-report-statistics-trend';
import {ChartAxisTickColored} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-colored';
import {ChartAxisTickHourTwoDigits} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-two-digits';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {ChartContainer} from '@/components/layout/chart-container';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformAverageSummary} from '@/model/transformers/transform-average-summary';
import {formatJSDateToApiDateTs} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {roundTo} from '@/util/number-util';
import {getSignalTypeIdentifier, getSignalTypeUnit} from '@/util/signal-type-util';

type HealthReportAggregatedSignalProps = {
    patientId: string;
    signalType: number;
    date: Date;
    signalEventConfiguration: SignalEventConfiguration;
    onClick?: (data: FormattedAggregatedSignalDataBlock) => void;
};

export type FormattedAggregatedSignalDataBlock = AggregatedSignalDataBlock & {
    at: StringDate & {
        hours: number;
    };
}

function HealthReportAggregatedSignal({patientId, signalType, date, signalEventConfiguration, onClick}: HealthReportAggregatedSignalProps) {
    const { t } = useTranslation();

    const [aggregatedSignalLoading, aggregatedSignal] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getAggregatedSignal,
        memoize: true,
        data: {
            patientId: { id: patientId },
            signalType: signalType,
            from: formatJSDateToApiDateTs(date),
            to: formatJSDateToApiDateTs(date),
        },
    }, [signalEventConfiguration]);

    const [signalAverageLoading, signalAverage] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getSignalAverageSummary,
        memoize: true,
        data: {
            patientId: { id: patientId },
            signalType: signalType,
            date: formatJSDateToApiDateTs(date),
            nbPreviousDays: 14,
        },
        transformer: transformAverageSummary,
    }, [signalEventConfiguration]);

    const signalTypeIdentifier = getSignalTypeIdentifier(signalType);
    const title = (() => {
        switch(signalType) {
        case SignalType.Signal_PR: return t('healthReport.aggregatedSignal.pulseRateTitle') ?? 'Pulse Rate - aggregated signal';
        case SignalType.Signal_SPO2: return t('healthReport.aggregatedSignal.spo2Title') ?? 'SpO2 - aggregated signal';
        case SignalType.Signal_Actigraphy: return ''; // not used
        default: return ''; // not used
        }
    })();

    const loading = (
        (aggregatedSignalLoading || !aggregatedSignal) ||
        (signalAverageLoading || !signalAverage)
    );

    if (loading) {
        return (
            <StyledWrapper className="health-report-aggregated-signal" color={colorPalette.signalTypeLight[signalTypeIdentifier]}>
                <ChartContainer title={title}>
                    <LoadingView color={colorPalette.signalType[signalTypeIdentifier]} />
                </ChartContainer>
            </StyledWrapper>
        );
    }

    const signalRange = [aggregatedSignal.valueMin, aggregatedSignal.valueMax];

    // Extend the aggregatedSignal blocks to add the "at.hours" field, for chart rendering
    aggregatedSignal.blocks = aggregatedSignal.blocks.map(block => {
        return {
            ...block,
            at: {
                ...block.at!,
                hours: Number(block.at!.time.split(':')[0]) + Number(block.at!.time.split(':')[1])/60.0,
            },
        };
    });

    const renderTooltip = (data: TooltipProps<number | string | Array<number | string>, string | number>) => {
        if (data?.payload) {
            const value = data?.payload[0]?.payload?.value.value;
            const time = data?.payload[0]?.payload?.at.time;

            return (
                <div className="chart-tooltip">
                    {(data?.payload[0]?.payload?.reliableValue?.value) ?
                        <span className="tooltip-value">
                            {t(`global.${signalTypeIdentifier}`)}: {roundTo(value, 2)}{getSignalTypeUnit(signalType)}
                        </span>
                        : (data?.payload[0]?.payload?.value === 'noData') ?
                            <span className="tooltip-no-data">
                                {t('global.noDataAvailable') ?? 'No data available'}
                            </span>
                            :
                            <span className="tooltip-not-reliable">
                                {t('global.notReliable') ?? 'Not Reliable'}
                            </span>
                    }
                    <span className="tooltip-time">{time}</span>
                </div>
            );
        }
        return null;
    };

    const domainDefinition = getDomainForSignalType(signalType, signalRange[0], signalRange[1]);

    if (patientId && date && signalEventConfiguration) {
        const topRightContent = (signalAverage && signalAverage.averageValue) ? (
            <div className="average-block">
                <p>
                    <HealthReportStatisticsTrend
                        trend={signalAverage.trend}
                    />
                    {t('healthReport.aggregatedSignal.avgRecording') ?? 'Last night avg:'}{' '}
                    <AverageValue averageObj={signalAverage.averageValue} unit={getSignalTypeUnit(signalType)}/>
                </p>
                <p>
                    {t('healthReport.aggregatedSignal.avgLast14Nights') ?? 'Prev. 14 nights avg:'}{' '}
                    <AverageValue averageObj={signalAverage.averagePrevious!} unit={getSignalTypeUnit(signalType)}/>
                </p>
            </div>
        ) : undefined;

        const tooltipText = (
            <p>
                {signalType === SignalType.Signal_PR && (
                    <>
                        {
                            t('infoButton.aggregatedSignal.pulseRate') ??
                            'This chart shows the last recording Pulse Rate signal as an aggregated view. You can access a specific time in the daily biometrics by clicking on a dot.'
                        }
                        &nbsp;
                    </>
                )}
                {signalType === SignalType.Signal_SPO2 && (
                    <>
                        {
                            t('infoButton.aggregatedSignal.spo2') ??
                            'This chart shows the last recording SpO2 signal as an aggregated view. You can access a specific time in the daily biometrics by clicking on a dot.'
                        }
                        &nbsp;
                    </>
                )}
                {t('infoButton.aggregatedSignal.reliableData') && 'Notice that not reliable data are colored in grey.'}
            </p>
        );

        const handleClick: CategoricalChartFunc = (data: CategoricalChartState) => {
            if (onClick) {
                if (data.activePayload && data.activePayload[0] && data.activePayload[0].payload) {
                    onClick(data.activePayload[0].payload as FormattedAggregatedSignalDataBlock);
                }
            }
        };

        return (
            <StyledWrapper className="health-report-aggregated-signal" color={colorPalette.signalTypeLight[signalTypeIdentifier]}>
                <ChartContainer
                    title={title}
                    topRightContent={topRightContent}
                    infoTooltipTitle={`${t(`global.${signalTypeIdentifier}`)} - ${t('infoButton.aggregatedSignal.title') ?? 'aggregated signal'}`}
                    infoTooltipText={tooltipText}
                    infoTooltipOverlayPosition="bottomRight"
                >
                    {(aggregatedSignal.blocks.length === 0) ? (
                        <div className="empty-message">{t('healthReport.aggregatedSignal.empty') ?? 'No recording for the last night'}</div>
                    ) : (
                        <LineChart data={aggregatedSignal.blocks} margin={{ top: 0, right: 0, bottom: 0, left: 10 }} onClick={handleClick}>
                            <XAxis
                                dataKey="at.hours"
                                interval={19}
                                tick={<ChartAxisTickHourTwoDigits textAnchor="middle" />}
                                padding={{ right: 20 }}
                            />
                            <YAxis
                                dataKey="value.value"
                                tick={(props) => <ChartAxisTickColored {...props} unit={getSignalTypeUnit(signalType)} fill={colorPalette.signalTypeLight[signalTypeIdentifier]} />}
                                domain={domainDefinition.domain}
                                ticks={domainDefinition.ticks}
                                interval={0}
                                width={56}
                                padding={{ top: 10, bottom: 10 }}
                            />
                            {signalEventConfiguration && signalType !== SignalType.Signal_SPO2 &&
                                <ReferenceLine
                                    y={signalEventConfiguration.high!.value}
                                    label={{
                                        position: (signalEventConfiguration.high!.value >= (domainDefinition.domain[1] - (5))) ? 'bottom' : 'top',
                                        value: `${signalEventConfiguration.high!.value}${getSignalTypeUnit(signalType)}`,
                                        fill: colorPalette.signalTypeClear[signalTypeIdentifier]
                                    }}
                                    stroke={colorPalette.signalTypeClear[signalTypeIdentifier]}
                                    strokeDasharray="4"
                                />
                            }
                            {signalEventConfiguration &&
                                <ReferenceLine
                                    y={signalEventConfiguration.low!.value}
                                    label={{
                                        position: (signalEventConfiguration.low!.value <= (domainDefinition.domain[0] + (5))) ? 'top' : 'bottom',
                                        value: `${signalEventConfiguration.low!.value}${getSignalTypeUnit(signalType)}`,
                                        fill: colorPalette.signalTypeClear[signalTypeIdentifier]
                                    }}
                                    stroke={colorPalette.signalTypeClear[signalTypeIdentifier]}
                                    strokeDasharray="4"
                                />
                            }
                            <Line
                                type="monotone"
                                dot={false}
                                dataKey="value.value"
                                stroke={colorPalette.clearColor}
                                strokeWidth={1.5}
                            />
                            <Line
                                type="monotone"
                                dot={false}
                                dataKey="reliableValue.value"
                                stroke={colorPalette.signalType[signalTypeIdentifier]}
                                strokeWidth={2}
                            />
                            <Tooltip
                                content={renderTooltip}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    )}
                </ChartContainer>
            </StyledWrapper>
        );
    }
    else {
        return <ComponentErrorMessage component="HealthReportAggregatedSignal" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    min-height: 260px;
    height: 100%;
    
    .tooltip-time {
        color: ${colorPalette.frontColor};
    }
    
    .tooltip-value {
        color: ${props => props.color};
    }
    
    .tooltip-no-data {
        color: ${colorPalette.clearColor};
    }
    
    .tooltip-not-reliable {
        color: ${colorPalette.clearColor};
    }

    svg {
        cursor: pointer;
    }
    
    .average-block {
        p span {
            color: ${props => props.color};
        }

        img.warningFlag {
            width: 15px;
            margin-right: 7px;
            margin-top: -3px;
        }
    }
}
`;

export {HealthReportAggregatedSignal};
