import {subDays} from 'date-fns';
import {BandAssignationStatus} from 'gabi-api-js/patient/common/patient_common_pb';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import {PatientLastRecording} from '@/components/business/analytics/patient/common/patient-last-recording';
import PatientUpdateOnboarding from '@/components/business/analytics/patient/patient-update/patient-update-onboarding';
import {Button} from '@/components/buttons/button';
import IconTooltip from '@/components/buttons/icon-tooltip';
import {Modal} from '@/components/layout/modal';
import {BandAssignationStatusLabel} from '@/enum/band-assignation-status';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import {PatientDelete} from '@/pages/analytics/patient/patient-delete';
import warningIcon from '@/public/icons/warning-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {BackendApiRefreshListeners} from '@/services/backend-api-refresh-listeners';
import {colorPalette} from '@/themes/darkmode';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';

type PatientsTableRowProps = {
    data: PatientTransformed;
};
function PatientsTableRow({data}: PatientsTableRowProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showPatientDeleteModal, setShowPatientDeleteModal] = useState(false);
    const [patientDeleted, setPatientDeleted] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState<boolean|null>(null);

    const isStudyPatient = data.trialId?.id;

    let healthReportView = 'last30Days';

    if (data.lastRecordingDate) {
        const lastRecordingDate = formatApiDateToJSDate(data.lastRecordingDate);
        const trendsDate = subDays(NewDateUtil(), 30);

        if(lastRecordingDate < trendsDate) {
            healthReportView = 'trends';
        }
    }

    const handleClickUpdateOnboarding = () => {
        setShowOnboarding(true);
    };

    const handleCloseUpdateOnboarding = () => {
        setShowOnboarding(false);
    };

    const handleOpenDeletePatientModal = () => {
        setShowPatientDeleteModal(true);
    };

    const handleOpenEditPatientPage = () => {
        const url = `/patient-update/${data.patientId?.id ?? ''}/patients/`;
        navigate(url);
    };

    const handleCloseModal = () => {
        setShowPatientDeleteModal(false);
    };

    const handlePatientDeleted = () => {
        setShowPatientDeleteModal(false);
        setPatientDeleted(true);
        BackendApiRefreshListeners.refreshByCall(PatientQueryServiceClient, PatientQueryServiceClient.prototype.getPatients);
        BackendApiRefreshListeners.refreshByCall(PatientQueryServiceClient, PatientQueryServiceClient.prototype.getPatientsWithRecording);
    };

    if (patientDeleted) {
        return (
            <StyledTr key={data.patientId?.id ?? ''} className="patients-table-row deleted">
                <td>
                    {t('patient.deleted') ?? 'Deleted'}
                </td>
                <td colSpan={5} className="patientNameCell">
                    <div>
                        <PatientAvatar skinType={data.skinType} />
                        <p>
                            {data.displayName}
                            <span>{t('patient.sexLabel.' + PatientChildSexLabel[data.sex])}</span>
                            <span>{data.trialId?.id}</span>
                        </p>
                    </div>
                </td>
            </StyledTr>
        );
    }
    else {
        return (
            <StyledTr key={data.patientId?.id ?? ''} className="patients-table-row">
                <td className="linkCell">
                    {data.assignationStatus === BandAssignationStatus.NO_QR_CODE_AVAILABLE &&
                        <IconTooltip icon={warningIcon} iconAltText={t(['Red warning icon', 'pages.patients.incompletePatientImgAltText'])}>
                            {t(['Please complete this patient information to generate a QR code for the caregiver.', 'pages.patients.incompletePatientText'])}
                        </IconTooltip>
                    }
                    {data.lastRecordingDate && (
                        <Link to={`/patients/${data.patientId?.id ?? ''}/healthReport/${healthReportView}`}>
                            {t(['Patient details', 'pages.patients.patientDetails'])}
                        </Link>
                    )}
                </td>
                <td className="patientNameCell">
                    <div>
                        <PatientAvatar skinType={data.skinType}/>
                        <p>
                            {data.displayName}
                            <span>
                                {data.birthDate ?
                                    <>{formatDateUniversal(formatApiDateToJSDate(data.birthDate))}&nbsp;-&nbsp;</>
                                    :
                                    <><em>{t(['No birth date information', 'patient.birthDateEmpty'])}&nbsp;-&nbsp;</em></>
                                }
                                {t('patient.sexLabel.' + PatientChildSexLabel[data.sex])}
                            </span>
                            <span>{data.trialId?.id}</span>
                        </p>
                    </div>
                </td>
                <td>
                    {data.assignationStatus === BandAssignationStatus.NO_BAND_ASSIGNED && (
                        <div className="qrcode-button">
                            {showOnboarding && (
                                <PatientUpdateOnboarding patientId={data.patientId?.id ?? ''} showModalOnboarding={true} onClosed={handleCloseUpdateOnboarding} />
                            )}
                            <Button
                                className="button-short-filled-hover-edit button-qrcode"
                                displayIcon={true}
                                onClick={handleClickUpdateOnboarding}
                            />
                        </div>
                    )}

                    {data.assignationStatus === BandAssignationStatus.HAS_RECORDING && data.lastRecordingDataTime &&
                        <PatientLastRecording lastRecordingDataTime={data.lastRecordingDataTime} />
                    }

                    {data.assignationStatus !== BandAssignationStatus.HAS_RECORDING &&
                        <em>{t(`global.bandAssignationStatus.${BandAssignationStatusLabel[data.assignationStatus]}`)}</em>
                    }
                </td>
                <td>
                    {(data.caregiverInformation) ?
                        <div className="caregiver-info">
                            {data.caregiverInformation.lastName}
                            {', '}
                            {data.caregiverInformation.firstName}<br/>
                            <a href={`tel:${data.caregiverInformation.phoneNumber}`}>{data.caregiverInformation.phoneNumber}</a><br/>
                            <a href={`mailto:${data.caregiverInformation.email}`}>{data.caregiverInformation.email}</a>
                        </div>
                        :
                        <em>{t('caregiver.noCaregiver', 'No caregiver information')}</em>
                    }
                </td>
                <td className="buttonCell">
                    {!isStudyPatient &&
                        <Button
                            className="button-short-filled-hover-edit button-edit-patient"
                            displayIcon={true}
                            onClick={handleOpenEditPatientPage}
                        >
                            {t(['Update patient', 'pages.patients.updatePatient'])}
                        </Button>
                    }
                </td>
                <td className="buttonCell">
                    {!data.lastRecordingDate && !data.bandSerial?.serial &&
                        <Button
                            className="button-short-filled-hover-delete button-remove-patient"
                            displayIcon={true}
                            onClick={handleOpenDeletePatientModal}
                        >
                            {t(['Delete patient', 'pages.patients.deletePatient'])}
                        </Button>
                    }
                    {showPatientDeleteModal &&
                        <Modal title={t(['Delete patient', 'pages.patients.deletePatient'])} onClosed={handleCloseModal} hideCloseButton>
                            <PatientDelete
                                patient={data}
                                onDeleted={handlePatientDeleted}
                                onCancelled={handleCloseModal}
                            />
                        </Modal>
                    }
                </td>
            </StyledTr>
        );
    }
}

//language=SCSS
const StyledTr = styled.tr`
& {
    &.deleted {
        background: ${colorPalette.delete};
    }
    
    .linkCell {
        text-align: center;
        > * {
          text-align: initial;
        }
    }
    
    .caregiver-info {
        max-width: 20vw;
        overflow: hidden; /* make sure it hides the content that overflows */
        text-overflow: ellipsis; /* give the beautiful '...' effect */
    }
    
    .button-qrcode {
        float: right;
        position: relative;
        top: -4px;
    }
    
    button {
        height: 35px;
    }
    
    .button-edit-patient,
    .button-delete-patient {
        display: inline-block;
    }
}
`;

export default PatientsTableRow;
