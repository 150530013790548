import {useTranslation} from 'react-i18next';

import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {roundDurationHoursToQuarterPrecision} from '@/util/time-utils';

type PatientRecordingDurationProps = {
    duration: number,
};
const PatientRecordingDuration = ({duration}: PatientRecordingDurationProps) => {
    const { t } = useTranslation();

    if (duration) {
        const durationHours = roundDurationHoursToQuarterPrecision(duration);

        if(!isNaN(duration)) {
            return (
                <>
                    {durationHours}h
                </>
            );
        }
        else {
            return <em className="invalid-duration-message">{t('patient.invalidDuration') ?? 'Invalid duration'}</em>;
        }
    }
    else {
        return <ComponentErrorMessage component="PatientRecordingDuration" />;
    }
};

export {PatientRecordingDuration};
