import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from 'recharts';
import styled from 'styled-components';

import {ChartAxisTickHourMinutesFromTimestamp} from '@/components/business/analytics/patient/patient-chart-axis/chart-axis-tick-hour-minutes-from-timestamp';
import {getDomainXForSignalSample} from '@/components/business/analytics/timeline/timeline-chart-domains';
import {getDomainForSignalType} from '@/components/business/analytics/timeline/timeline-chart-domains-old';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {getSignalTypeIdentifier, SignalTypeEnum} from '@/enum/signal-type-enum';
import {colorPalette} from '@/themes/darkmode';
import {formatDateTimeUniversal} from '@/util/date-utils';
import {roundTo} from '@/util/number-util';

const HealthReportActigraphySignalSample = ({actigraphyData, dateFrom, duration}) => {
    const { t } = useTranslation();
    
    const renderTooltip = (data) => {
        if (data?.payload) {
            const color = (data?.payload[0]?.payload?.reliability && data?.payload[0]?.payload?.reliableValue.value && data?.payload[0]?.payload?.reliableValue.value !== 'noData') ?
                colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.MOVEMENTS)] :
                colorPalette.clearColor;

            return (
                <div className="chart-tooltip">
                    <strong style={{color: color}}>
                        <div>{t(['Movements', 'global.movements'])}:&nbsp;
                            {(data?.payload[0]?.payload?.reliability && data?.payload[0]?.payload?.reliableValue?.value) ?
                                roundTo(data?.payload[0]?.payload?.reliableValue?.value, 2) + 'G'
                                : (data?.payload[0]?.payload?.value === 'noData') ?
                                    t(['No data available', 'global.noDataAvailable']) :
                                    t(['Not Reliable', 'global.notReliable'])
                            }
                        </div>
                    </strong>
                    {formatDateTimeUniversal(new Date(data?.payload[0]?.payload?.at))}
                </div>
            );
        }
        return null;
    };

    const formatYAxis = (tickItem) => {
        if (tickItem === Math.floor(tickItem)) {
            return tickItem;
        } else {
            return tickItem.toFixed(2);
        }
    };

    if (actigraphyData) {
        const xAxisDomain = getDomainXForSignalSample(dateFrom.getTime(), duration);
        
        return (
            <StyledWrapper>
                <LineChart data={actigraphyData} width={450} height={100}>
                    <CartesianGrid strokeDasharray="1 3" />

                    <XAxis
                        dataKey="at"
                        type="number"
                        tick={<ChartAxisTickHourMinutesFromTimestamp textAnchor="right" />}
                        ticks={xAxisDomain.ticks}
                        domain={xAxisDomain.domain}
                        interval={0}
                    />
                    <YAxis domain={getDomainForSignalType(SignalTypeEnum.MOVEMENTS).domain} unit={'G'} type="number" tickCount={4} tickFormatter={formatYAxis} />

                    <Line
                        isAnimationActive={false}
                        type="linear"
                        dot={false}
                        activeDot={false}
                        dataKey="value"
                        stroke={colorPalette.clearColor}
                        strokeWidth={1.2}
                        connectNulls={false}
                    />
                    <Line
                        isAnimationActive={false}
                        type="linear"
                        dot={false}
                        activeDot={false}
                        dataKey="reliableValue.value"
                        stroke={colorPalette.signalType[getSignalTypeIdentifier(SignalTypeEnum.MOVEMENTS)]}
                        strokeWidth={1.5}
                        opacity={1}
                        connectNulls={false}
                    />
                    <Tooltip
                        content={renderTooltip}
                        data={actigraphyData}
                        isAnimationActive={false}
                    />
                </LineChart>
            </StyledWrapper>
        );
    } else {
        return <ComponentErrorMessage component="HealthReportActigraphySignalSample" />;
    }
};

HealthReportActigraphySignalSample.propTypes = {
    actigraphyData: PropTypes.array.isRequired,
    dateFrom: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    margin-top: 5px;
}
`;

export {HealthReportActigraphySignalSample};
