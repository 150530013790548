import deepEqual from 'deep-eql';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@/components/buttons/button';
import {WarningMessage} from '@/components/form/warning-message';
import {Patient} from '@/types/patient';
import {Prescriber} from '@/types/prescriber';

type PatientPrescriberFormProps = {
    className?: string;
    loading: boolean;
    me: {
        sub: string;
    };
    patient?: Patient;
    prescriberSelected?: string;
    prescribers: Prescriber[];
    onChanged?: (hasChanged: boolean) => void;
    onSubmit: (payload: PatientPrescriberFormPayload) => void;
};

type PatientPrescriberFormPayload = {
    id: string;
};

function PatientPrescriberForm({className, loading, me, patient, prescribers, prescriberSelected, onChanged, onSubmit}: PatientPrescriberFormProps) {
    const { t } = useTranslation();
    const [prescriberId, setPrescriberId] = useState<string>(prescriberSelected ?? '');

    const isFilled = () => {
        return (
            prescriberId && prescriberId !== ''
        );
    };

    const checkNotSavedDataPrescriber = () => {
        const notSavedDataPrescriber = !deepEqual(prescriberSelected, prescriberId);
        if (onChanged) {
            onChanged(notSavedDataPrescriber);
        }
    };

    useEffect(() => {
        checkNotSavedDataPrescriber();
    }, [prescriberId, prescriberSelected]);

    const handleChangePrescriberId = (e: ChangeEvent<HTMLSelectElement>) => {
        setPrescriberId(e.target.value);
        if (onChanged) {
            onChanged(false);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (onSubmit) {
            if (onChanged) {
                onChanged(false);
            }
            onSubmit({
                id: prescriberId,
            });
        }
    };

    if (prescriberSelected === me.sub?.split('|')[1] || prescriberSelected === '') {
        return (
            <div className={`patient-prescriber-form ${className ?? ''}`}>
                <form onSubmit={handleSubmit}>
                    <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                    <div className="fieldWrapper">
                        <label htmlFor="patientPrescriber-id" className={`mandatoryField ${(prescriberId === '') ? 'empty' : 'filled'}`}>{t(['Prescriber', 'prescriber.title'])}</label>
                        <select name="id" id="patientPrescriber-id" required aria-required="true" autoFocus value={prescriberId} onChange={handleChangePrescriberId}>
                            <option value="">{t(['Choose a prescriber...', 'prescriber.choose'])}</option>
                            {prescribers.map((prescriber) => (
                                <option key={prescriber.id} value={prescriber.id}>
                                    {prescriber.details?.lastName ?? ''}{', '}{prescriber.details?.firstName ?? ''}
                                    {' <'}{prescriber.details?.email}{'>'}
                                </option>
                            ))}
                        </select>
                    </div>

                    {(prescriberId !== '' && prescriberId !== me.sub?.split('|')[1]) &&
                        <WarningMessage
                            warningMessage={t(['You selected another prescriber than yourself.', 'prescriber.notYourselfWarning'])}
                            text={t(['With this selection, you will not be able to modify the prescriber later.', 'prescriber.notYourselfWarningText'])}
                        />
                    }

                    <div className="step-form-actions">
                        <Button className="button-create" disabled={!isFilled()} loading={loading}>{(patient) ? t(['Save', 'prescriber.save']) : t(['Next', 'prescriber.next'])}</Button>
                    </div>
                </form>
            </div>
        );
    }
    else {
        const selectedPrescriberDetails = prescribers.find(x => x.id === prescriberSelected);
        return (
            <div className={`patient-prescriber-form ${className ?? ''}`}>
                {selectedPrescriberDetails && (
                    <span>
                        {selectedPrescriberDetails.details?.lastName ?? ''}{', '}
                        {selectedPrescriberDetails.details?.firstName ?? ''}{' '}
                        {selectedPrescriberDetails.details?.email &&
                            <span>{'<'}{selectedPrescriberDetails.details?.email}{'>'}</span>
                        }
                    </span>
                )}
            </div>
        );
    }
}

export {PatientPrescriberForm};
