import {AlertType} from 'gabi-api-js/signal/query/signal_query_pb';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {colorPalette} from '@/themes/darkmode';
import {formatApiDateShortUniversal, formatApiTimeUniversal} from '@/util/apidate-util';

const alertTypeClass = {};
alertTypeClass[AlertType.ALERT_HIGH] = 'high';
alertTypeClass[AlertType.ALERT_LOW] = 'low';

function HealthReportEventsListTableRow({data, onShowSignalSample}) {
    const { t } = useTranslation();

    const getEventValue = (value) => {
        if(value) {
            return <span><strong>{value}</strong> {t('global.seconds') ?? 'sec'}</span>;
        }
        else {
            return '-';
        }
    };

    const handleClickShowSignalSampleButton = (e) => {
        e.preventDefault();
        onShowSignalSample(data);
    };

    return (
        <StyledWrapper>
            <td className="linkCell">
                <button className="show-signal-sample-button" onClick={(e) => handleClickShowSignalSampleButton(e, data)}>
                    Signal sample
                </button>
            </td>
            <td className="eventDateCell">
                {formatApiDateShortUniversal(data.timestamp.date)}
                {' '}
                {formatApiTimeUniversal(data.timestamp.time)}
            </td>
            <td className={`eventsCell event-cell-${data.signalType}-${alertTypeClass[data.alertType]}`}>
                {getEventValue(data.durationInSeconds)}
            </td>
        </StyledWrapper>
    );
}

HealthReportEventsListTableRow.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    onShowSignalSample: PropTypes.func,
    columns: PropTypes.array,
};

//language=SCSS
const StyledWrapper = styled.tr`
& {
    th {
        font-size: 12px;
    }
    
    .linkCell {
        padding: 5px;
    }
    
    .eventsCell {
        width: 30%;
        min-width: 110px;
        max-width: 140px;
        border-left: 4px solid transparent;
        strong {
            font-size: 16px;
        }
        &.event-cell-0-high {
            border-left-color: ${colorPalette.signalType.pulseRate};
        }
        &.event-cell-0-low {
            border-left-color: ${colorPalette.signalTypeLight.pulseRate};
        }
        &.event-cell-1-low {
            border-left-color: ${colorPalette.signalType.spo2};
        }
    }
}
`;

export {HealthReportEventsListTableRow};
