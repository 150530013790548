import {
    Gender,
    HcpAddition,
    Language,
    LegalFlags,
    LegalFlagStatus,
    PersonalDetails as HcpPersonalDetails,
    ProgramType
} from 'gabi-api-ts/v2/hcp/common/hcp_common';
import {HcpEntry} from 'gabi-api-ts/v2/hcp/query/hcp_query';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientsProgramSelection from '@/components/business/analytics/patients/patients-program-selection';
import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';
import {PhonenumberInput, PhoneNumberWithInputInfo} from '@/components/form/input/phonenumber-input';
import {SelectCountry} from '@/components/form/input/select-country';
import {GenderEnum} from '@/enum/gender-enum';
import {useTriggerRender} from '@/hooks/use-trigger-render';
import {GeoCountryService} from '@/services/geocontry-service';
import {colorPalette} from '@/themes/darkmode';
import {isEmailValid} from '@/util/email-util';
import {noop} from '@/util/noop';
import {isPhonenumberValid} from '@/util/phonenumber-util';

const defaultHcpEntry = {
    personalDetails: {
        address: {
            city: '',
            state: '',
            country: '',
        },
        email: '',
        firstName: '',
        gender: Gender.GENDER_NOT_SET,
        lastName: '',
        phoneNumber: undefined,
        preferredLanguage: Language.LANGUAGE_NOT_SET,
    },
    legalFlags: {
        privacyPolicy: LegalFlagStatus.NOT_ACCEPTED,
        termsOfUse: LegalFlagStatus.NOT_ACCEPTED,
    },
    programType: ProgramType.RESEARCH,
};

type HcpPersonalDetailsWithInputInfo = HcpPersonalDetails & {
    phoneNumber?: PhoneNumberWithInputInfo;
};
type HcpEntryWithInputInfo = HcpEntry & HcpAddition & {
    personalDetails?: HcpPersonalDetailsWithInputInfo;
};
type HcpPersonalDataFormProps = {
    className?: string;
    creationMode?: boolean;
    hcp?: HcpEntry;
    submitting: boolean;
    onSubmit?: (hcpData: HcpEntryWithInputInfo) => void;
};
function HcpPersonalDataFormUnstyled({className, creationMode, hcp, submitting, onSubmit}: HcpPersonalDataFormProps) {
    const { t } = useTranslation();
    const triggerRender = useTriggerRender();
    const [currentHcp, setCurrentHcp] = useState<HcpEntryWithInputInfo>(
        (hcp && {
            legalFlags: hcp.legalFlags ? { ...hcp.legalFlags } : { ...defaultHcpEntry.legalFlags },
            personalDetails: hcp.personalDetails ? { ...hcp.personalDetails } : { ...defaultHcpEntry.personalDetails },
            programType: ProgramType.RESEARCH,
        }) ?? defaultHcpEntry
    );
    function setPersonalDetails(updateFn: (details: HcpPersonalDetailsWithInputInfo) => HcpPersonalDetailsWithInputInfo) {
        setCurrentHcp(state => ({
            ...state,
            personalDetails: updateFn(state.personalDetails ?? defaultHcpEntry.personalDetails),
        }));
    }
    function setLegalFlags(updateFn: (details: LegalFlags) => LegalFlags) {
        setCurrentHcp(state => ({
            ...state,
            legalFlags: updateFn(state.legalFlags ?? defaultHcpEntry.legalFlags),
        }));
    }

    useEffect(() => {
        // check invitation email in url
        const urlParams = document.location.search.substring(1);
        if (document.location.href.indexOf('invitation') > 0) {
            if (urlParams.split('=')[0] === 'invitation') {
                setPersonalDetails(state => ({
                    ...state,
                    email: urlParams.split('=')[1],
                }));
            }
        }
    }, []);

    useEffect(() => {
        getGeoCountry().then(noop);
    }, []);

    const personalDetails = currentHcp.personalDetails;
    const legalFlags = currentHcp.legalFlags;

    let stepCounter = 0;

    return (
        <div className={`hcp-personal-data-form ${className ?? ''}`}>
            <form onSubmit={handleSubmit}>
                <p><em>{t('global.mandatoryFields')}</em></p>
                <h3><span>{++stepCounter}</span> {t('hcpPersonalDataForm.steps.personalInformation') ?? 'Personal information'}</h3>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-firstName" className={`mandatoryField ${(personalDetails?.firstName && personalDetails?.firstName !== '') ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.firstName')}
                        </label>
                        <input
                            type="text"
                            id="hcp-firstName"
                            required aria-required="true"
                            autoFocus
                            name="firstName"
                            maxLength={70}
                            value={personalDetails?.firstName}
                            onChange={handleChangeFirstName}
                            disabled={submitting}
                        />
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-lastName" className={`mandatoryField ${(personalDetails?.lastName && personalDetails?.lastName !== '') ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.lastName')}
                        </label>
                        <input
                            type="text"
                            id="hcp-lastName"
                            required aria-required="true"
                            name="lastName"
                            maxLength={70}
                            value={personalDetails?.lastName}
                            onChange={handleChangeLastName}
                            disabled={submitting}
                        />
                    </div>
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="hcp-gender" className={`mandatoryField ${(personalDetails && personalDetails?.gender !== Gender.GENDER_NOT_SET) ? 'filled' : 'empty'}`}>
                        {t('hcpPersonalDataForm.gender')}
                    </label>
                    <select name="gender" id="hcp-gender" required aria-required="true" value={personalDetails?.gender} onChange={handleChangeGender} disabled={submitting}>
                        <option value=""></option>
                        {Object.entries(GenderEnum).map((gender) => (
                            <option key={gender[1]} value={gender[1]}>{t('global.gender.' + gender[0])}</option>
                        ))}
                    </select>
                </div>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-address-state" className={`mandatoryField ${(personalDetails?.address?.state && personalDetails.address.state !== '') ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.state')}
                        </label>
                        <input
                            type="text"
                            id="hcp-address-state"
                            name="address-state"
                            required aria-required="true"
                            value={personalDetails?.address?.state}
                            onChange={handleChangeAddressState}
                            disabled={submitting}
                        />
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-address-city" className={`mandatoryField ${(personalDetails?.address?.city && personalDetails.address.city !== '') ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.city')}
                        </label>
                        <input
                            type="text"
                            id="hcp-address-city"
                            name="address-city"
                            required aria-required="true"
                            value={personalDetails?.address?.city}
                            onChange={handleChangeAddressCity}
                            disabled={submitting}
                        />
                    </div>
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="hcp-address-country" className={`mandatoryField ${(personalDetails?.address?.country && personalDetails.address.country !== '') ? 'filled' : 'empty'}`}>
                        {t('hcpPersonalDataForm.country')}
                    </label>
                    <SelectCountry
                        name="address-country"
                        id="hcp-address-country"
                        required={true}
                        aria-required="true"
                        value={personalDetails?.address?.country}
                        onChange={handleChangeAddressCountry}
                        disabled={submitting}
                    />
                </div>
                <div className="fieldWrapper">
                    <label htmlFor="hcp-phone-number" className={`mandatoryField ${(personalDetails?.phoneNumber?.number && personalDetails?.phoneNumber?.number !== '') && isPhonenumberValid(personalDetails.phoneNumber) ? 'filled' : 'empty'}`}>
                        {t('hcpPersonalDataForm.phoneNumber')}
                    </label>
                    <PhonenumberInput
                        id="hcp-phone-number"
                        value={personalDetails?.phoneNumber}
                        onBlur={triggerRender}
                        onChange={handleChangePhoneNumber}
                        disabled={submitting}
                    />
                    {personalDetails?.phoneNumber && !personalDetails.phoneNumber.empty && !isPhonenumberValid(personalDetails.phoneNumber) && (
                        <div className="errorWrapper">
                            <ErrorMessage text={''} errorMessage={t('hcpPersonalDataForm.phoneErrorMessage') ?? 'Please enter a valid email address'} />
                        </div>
                    )}
                </div>
                {creationMode && (
                    <div className="fieldWrapper">
                        <label htmlFor="hcp-email" className={`mandatoryField ${(personalDetails?.email && personalDetails?.email !== '') ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.email') ?? 'Email'}
                        </label>
                        <input
                            type="text"
                            id="hcp-email"
                            required aria-required="true"
                            name="email"
                            maxLength={320}
                            value={personalDetails?.email}
                            onBlur={triggerRender}
                            onChange={handleChangeEmail}
                            disabled={submitting}
                        />
                        {personalDetails?.email !== '' && !isEmailValid(personalDetails?.email) && (
                            <ErrorMessage text={''} errorMessage={t('hcpPersonalDataForm.emailError') ?? 'Please enter a valid email address'} />
                        )}
                    </div>
                )}
                {/* ------------------------------------------------------------------------------------------------ */}

                {creationMode && (<>
                    <h3><span>{++stepCounter}</span> {t('hcpPersonalDataForm.steps.programConfiguration') ?? 'Program configuration'}</h3>
                    <div className="fieldWrapper programTypeWrapper">
                        <p>{t('hcpUpdateConfiguration.program.text') ?? 'Please select a program type, this will update the columns displayed in your active patients dashboard.'}</p>
                        <PatientsProgramSelection onChanged={handleChangeProgram} program={currentHcp.programType} />
                    </div>
                </>)}
                {/* ------------------------------------------------------------------------------------------------ */}

                <h3><span>{++stepCounter}</span> {t('hcpPersonalDataForm.steps.termsAndConditions') ?? 'Terms and conditions'}</h3>
                <div className="fieldWrapper">
                    <div className="checkboxFieldWrapper">
                        <input
                            type="checkbox"
                            id="privacyPolicy"
                            required aria-required="true"
                            name="legalFlag-privacyPolicy"
                            checked={legalFlags?.privacyPolicy === LegalFlagStatus.ACCEPTED}
                            disabled={(hcp?.legalFlags?.privacyPolicy === LegalFlagStatus.ACCEPTED) && (!creationMode || submitting)}
                            onChange={handleLegalFlagPrivacyPolicy}
                        />
                        <label htmlFor="privacyPolicy" className={`mandatoryCheckbox ${(legalFlags?.privacyPolicy === LegalFlagStatus.ACCEPTED) ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.iAgree') ?? 'I agree with the'}{' '}
                            <a target="_blank" rel="noreferrer" href={`https://help.gabismartcare.com/hcp-privacy-policy-${__APP_VERSION__}`}>
                                {t('hcpPersonalDataForm.privacyPolicy') ?? 'Privacy policy'}
                            </a>
                        </label>

                    </div>
                </div>
                <div className="fieldWrapper">
                    <div className="checkboxFieldWrapper">
                        <input
                            type="checkbox"
                            id="termsOfUse"
                            required aria-required="true"
                            name="legalFlag-termsOfUse"
                            checked={legalFlags?.termsOfUse === LegalFlagStatus.ACCEPTED}
                            disabled={(hcp?.legalFlags?.termsOfUse === LegalFlagStatus.ACCEPTED) && (!creationMode || submitting)}
                            onChange={handleLegalFlagTermsOfUse}
                        />
                        <label htmlFor="termsOfUse" className={`mandatoryCheckbox ${(legalFlags?.termsOfUse === LegalFlagStatus.ACCEPTED) ? 'filled' : 'empty'}`}>
                            {t('hcpPersonalDataForm.iAgree') ?? 'I agree with the'}{' '}
                            <a target="_blank" rel="noreferrer" href={`https://help.gabismartcare.com/hcp-tou-${__APP_VERSION__}`}>
                                {t('hcpPersonalDataForm.termsOfUse') ?? 'Terms of use'}
                            </a>
                        </label>
                    </div>
                </div>
                <div className="step-form-actions">
                    <Button className="button-create" disabled={!isFilled(currentHcp)} loading={submitting}>
                        {creationMode ? t('hcpPersonalDataForm.create') : t('hcpPersonalDataForm.update')}
                    </Button>
                </div>
            </form>
        </div>
    );

    function handleChangeFirstName(e: ChangeEvent<HTMLInputElement>) {
        setPersonalDetails(state => ({
            ...state,
            firstName: e.target?.value ?? '',
        }));
    }

    function handleChangeLastName(e: ChangeEvent<HTMLInputElement>) {
        setPersonalDetails(state => ({
            ...state,
            lastName: e.target.value,
        }));
    }

    function handleChangeGender(e: ChangeEvent<HTMLSelectElement>) {
        setPersonalDetails(state => ({
            ...state,
            gender: (e.target.value === '') ? Gender.GENDER_NOT_SET : parseInt(e.target.value),
        }));
    }

    function handleChangePhoneNumber(e: ChangeEvent<{value: PhoneNumberWithInputInfo}>) {
        const parsedPhoneNumber = e.target?.value;
        if (parsedPhoneNumber) {
            setPersonalDetails(state => ({
                ...state,
                phoneNumber: parsedPhoneNumber,
            }));
        }
        else {
            setPersonalDetails(state => ({
                ...state,
                phoneNumber: {
                    number: '',
                    countryCode: '',
                    isValid: false,
                },
            }));
        }
    }

    function handleChangeAddressState(e: ChangeEvent<HTMLInputElement>) {
        setPersonalDetails(state => ({
            ...state,
            address: {
                ...(state.address ?? defaultHcpEntry.personalDetails.address),
                state: e.target.value,
            },
        }));
    }

    function handleChangeAddressCity(e: ChangeEvent<HTMLInputElement>) {
        setPersonalDetails(state => ({
            ...state,
            address: {
                ...(state.address ?? defaultHcpEntry.personalDetails.address),
                city: e.target.value,
            },
        }));
    }

    function handleChangeAddressCountry(e: ChangeEvent<HTMLSelectElement>) {
        setPersonalDetails(state => ({
            ...state,
            address: {
                ...(state.address ?? defaultHcpEntry.personalDetails.address),
                country: e.target.value,
            },
        }));
    }

    function handleChangeEmail(e: ChangeEvent<HTMLInputElement>) {
        setPersonalDetails(state => ({
            ...state,
            email: e.target.value,
        }));
    }

    function handleChangeProgram(programType: ProgramType) {
        setCurrentHcp(state => ({
            ...state,
            programType: programType,
        }));
    }

    function handleLegalFlagPrivacyPolicy(e: ChangeEvent<HTMLInputElement>) {
        setLegalFlags(state => ({
            ...state,
            privacyPolicy: e.target.checked ? LegalFlagStatus.ACCEPTED : LegalFlagStatus.NOT_ACCEPTED,
        }));
    }

    function handleLegalFlagTermsOfUse(e: ChangeEvent<HTMLInputElement>) {
        setLegalFlags(state => ({
            ...state,
            termsOfUse: e.target.checked ? LegalFlagStatus.ACCEPTED : LegalFlagStatus.NOT_ACCEPTED,
        }));
    }

    async function getGeoCountry() {
        try {
            const countryCode = await GeoCountryService.fetchCountryCode();
            if (!(currentHcp.personalDetails?.address?.country) || currentHcp.personalDetails?.address?.country === '') {
                setPersonalDetails(state => ({
                    ...state,
                    address: {
                        ...(state.address ?? defaultHcpEntry.personalDetails.address),
                        country: countryCode,
                    },
                }));
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    function handleSubmit(e: FormEvent) {
        e.preventDefault();

        if (onSubmit && currentHcp) {
            onSubmit(currentHcp);
        }
    }
}

function isFilled(hcp: HcpEntryWithInputInfo | null) {
    return hcp && hcp.personalDetails && hcp.legalFlags && (
        hcp.personalDetails.firstName && hcp.personalDetails.firstName !== '' &&
        hcp.personalDetails.lastName && hcp.personalDetails.lastName !== '' &&
        hcp.personalDetails.gender !== Gender.GENDER_NOT_SET &&
        hcp.personalDetails.phoneNumber && !hcp.personalDetails.phoneNumber.empty &&
        hcp.personalDetails.address && (
            hcp.personalDetails.address.state !== '' &&
            hcp.personalDetails.address.city !== '' &&
            hcp.personalDetails.address.country !== ''
        ) &&
        hcp.personalDetails.email && hcp.personalDetails.email !== '' &&
        isEmailValid(hcp.personalDetails.email) &&
        hcp.personalDetails.phoneNumber &&
        isPhonenumberValid(hcp.personalDetails.phoneNumber) &&
        hcp.legalFlags.privacyPolicy === LegalFlagStatus.ACCEPTED &&
        hcp.legalFlags.termsOfUse === LegalFlagStatus.ACCEPTED &&
        true
    );
}

//language=SCSS
const HcpPersonalDataForm = Styled(HcpPersonalDataFormUnstyled)`
& {
    h3 {
        position: relative;
        font-size: 16px !important;
        border-bottom: none !important;
        margin-bottom: 20px;
        margin-top: 30px;
        padding-left: 40px;
        padding-top: 7px;
        span {
            display: block;
            padding: 6px 5px;
            color: ${colorPalette.frontColor};
            border-radius: 50px;
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: ${colorPalette.activeColor};
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}`;

export {HcpPersonalDataForm};
