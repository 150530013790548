import {subMinutes} from 'date-fns';
import {EventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common';
import {SignalType, TypedEventsList} from 'gabi-api-ts/v2/signal/query/signal_query';
import {TypedEvent} from 'gabi-api-ts/v2/signal/query/signal_query.ts';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {HealthReportEventsListTableRow} from '@/components/business/analytics/health-report/health-report-events-list-table-row';
import HealthReportSignalSample from '@/components/business/analytics/health-report/health-report-signal-sample';
import ColoredContainer from '@/components/layout/colored-container';
import {Modal} from '@/components/layout/modal';
import {TableSortable} from '@/components/table/table-sortable';
import {TableSortableColumn} from '@/components/table/table-sortable-types';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette} from '@/themes/darkmode';
import {formatApiDateTimeUniversal, getDaysDifference} from '@/util/apidate-util';
import {getSignalTypeIdentifier} from '@/util/signal-type-util';

type HealthReportEventsListProps = {
    className?: string;
    patientId: string;
    eventsList: TypedEventsList;
    eventConfiguration: EventConfiguration;
};

type FormattedTypedEvent = (TypedEvent & { patientId: string, timestampNumber: number });

function HealthReportEventsList({className, patientId, eventsList, eventConfiguration}: HealthReportEventsListProps) {
    const { t } = useTranslation();
    const [ showSignalSample, setShowSignalSample ] = useState(false);
    const [ signalSampleData, setSignalSampleData ] = useState<FormattedTypedEvent|null>(null);

    const tableSortableHeaderColumn: TableSortableColumn<FormattedTypedEvent>[] = [
        {
            id: 'id',
            label: '',
            sortable: false,
        },
        {
            id: 'timestampNumber',
            label: t('healthReport.events.list.eventTime'),
            className: 'durationTableHeader',
            sortable: true,
            compareFn: (a, b) => {
                return a.timestampNumber - b.timestampNumber;
            },
        },
        {
            id: 'durationInSeconds',
            label: t('healthReport.events.list.eventDuration'),
            className: 'signalTableHeader',
            sortable: true,
            compareFn: (a, b) => {
                return a.durationInSeconds - b.durationInSeconds;
            },
        },
    ];

    const getSignalEventConfiguration = (signalType: SignalType) => {
        switch(signalType) {
        case SignalType.Signal_PR:
            return eventConfiguration.configuration!.hr;
        case SignalType.Signal_SPO2:
            return eventConfiguration.configuration!.spo2;
        default:
            return eventConfiguration.configuration!.hr;
        }
    };

    const handleShowSignalSample = (rowData: FormattedTypedEvent) => {
        setShowSignalSample(true);
        setSignalSampleData(rowData);
    };

    const onCloseSignalSample = () => {
        setShowSignalSample(false);
    };

    if (patientId && eventConfiguration) {
        const eventsListFormatted: FormattedTypedEvent[] = [];
        eventsList!.event.forEach(event => {
            if (event.signalType === SignalType.Signal_PR || event.signalType === SignalType.Signal_SPO2) {
                const time = event.timestamp!.time!;
                eventsListFormatted.push({
                    patientId: patientId,
                    alertType: event.alertType,
                    durationInSeconds: event.durationInSeconds,
                    signalType: event.signalType,
                    timestamp: event.timestamp,
                    timestampNumber: getDaysDifference({year: 2020, month: 1, day: 1}, event.timestamp!.date!)*24*3600*1000 + time.hour*3600000 + time.minutes*60000 + time.seconds * 1000 + time.miliSeconds,
                });
            }
        });

        return (
            <StyledWrapper className={`health-report-events-list ${className ?? ''}`}>
                {(showSignalSample && signalSampleData) && (
                    <Modal title={''} onClosed={onCloseSignalSample}>
                        <ColoredContainer
                            color={colorPalette.signalType[getSignalTypeIdentifier(signalSampleData.signalType)]}
                            title={`${t(`global.${getSignalTypeIdentifier(signalSampleData.signalType)}`)} - ${t('healthReport.events.list.signalSampleTitle') ?? 'event - signal sample'}`}
                        >
                            <HealthReportSignalSample
                                patientId={patientId}
                                signalType={signalSampleData.signalType}
                                eventTimestamp={signalSampleData.timestamp!}
                                dateFrom={subMinutes(formatApiFullDateToJSDate(signalSampleData.timestamp), 1)}
                                duration={300}
                                signalEventConfiguration={getSignalEventConfiguration(signalSampleData.signalType)!}
                            />
                        </ColoredContainer>
                    </Modal>
                )}
                {(eventsListFormatted.length > 0) && (
                    <div className="events-list-scrollable">
                        <TableSortable
                            data={eventsListFormatted}
                            keyFn={item => `${item.signalType}-${item.alertType}-${formatApiDateTimeUniversal(item.timestamp!.date!, item.timestamp!.time!)}`}
                            columns={tableSortableHeaderColumn}
                            defaultSorting={{id: 'timestampNumber', sortByOrder: 'asc'}}
                            TableBodyRowComponent={(props) => <HealthReportEventsListTableRow {...props} onShowSignalSample={handleShowSignalSample}/>}
                        />
                    </div>
                )}
                {(eventsListFormatted.length === 0) && (
                    <div className="empty-message">{t('healthReport.events.list.empty') ?? 'No events for the last night.'}</div>
                )}
            </StyledWrapper>
        );
    }
    else {
        return <ComponentErrorMessage component="HealthReportEventsList" />;
    }
}

//language=SCSS
const StyledWrapper = styled.div`
& {
    height: 100%;
    position: relative;
    h3 {
        font-size: 12px;
        margin-bottom: 25px;
        color: ${props => props.theme.colorPalette.clearColor};
    }
    
    .events-list-scrollable {
        @supports (scroll-timeline: --scrollTimeline y) {
            scroll-timeline: --scrollTimeline y;
        }
        
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        border-radius: 5px;
        
        ::-webkit-scrollbar {
            display: none;
        }
        
        table {
            background: ${colorPalette.secondBackground};
            border-radius: 5px;
            overflow-x: hidden;
        }
        
        thead {
            position: sticky;
            top: 0;
            z-index: 2;
            tr {
                position: relative;
    
                @supports (scroll-timeline: --scrollTimeline y) {
                    clip-path: inset(0 1px -10px 1px);
                    animation-name: health-report-events-list__scroll-animation;
                    animation-duration: 1ms; /* Firefox requires this to apply the animation */
                    animation-timing-function: linear;
                    animation-timeline: --scrollTimeline;
                }
                
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background: ${colorPalette.mainBackground};
                }
            }
            th {
                position: relative;
                box-shadow: 0 0 0 1px ${colorPalette.mainBackground};
                z-index: 2;
                background-color: ${colorPalette.secondBackground};
                margin: 0;
                border-left: none;
                border-right: none;
                
                &:not(:last-child) {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 100%;
                        top: 0;
                        right: 0;
                        background: ${colorPalette.mainBackground};
                    }
                }
            }
        }
        
        tbody {
            position: relative;
            z-index: 1;
            
            td {
              position: relative;
              z-index: 1;
            }
        }
    }
}
`;

export {HealthReportEventsList};
