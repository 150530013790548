import MainAnalyticsAuth0Wrapper from '@/main-analytics-auth0-wrapper';
import {GroupsPage} from '@/pages/analytics/group/groups';
import {HcpUpdatePage} from '@/pages/analytics/hcp/hcp-update';
import {HealthReportLast30DaysPage} from '@/pages/analytics/health-report/health-report-last-30-days';
import {HealthReportLast7DaysPage} from '@/pages/analytics/health-report/health-report-last-7-days';
import {HealthReportLastAvailableNightPage} from '@/pages/analytics/health-report/health-report-last-available-night';
import {HealthReportTrendsPage} from '@/pages/analytics/health-report/health-report-trends';
import {PatientCreationPage} from '@/pages/analytics/patient/patient-creation';
import {PatientUpdatePage} from '@/pages/analytics/patient/patient-update';
import {PatientsActivePage} from '@/pages/analytics/patients/patients-active';
import {PatientsInactivePage} from '@/pages/analytics/patients/patients-inactive';
import PatientsSearchPage from '@/pages/analytics/patients/patients-search';
import {PatientTimelinePage} from '@/pages/analytics/timeline/timeline';
import {PatientTimelineRedirectPage} from '@/pages/analytics/timeline/timeline-redirect';
import {ChangelogPage} from '@/pages/changelog';
import Err404 from '@/pages/err404';

const routes = [
    {
        path: '/',
        element: <MainAnalyticsAuth0Wrapper />,
        children: [
            {
                path: '*',
                element: <Err404 />
            },
            {
                path: '/',
                element: <PatientsActivePage />
            },
            {
                path: '/patients/',
                element: <PatientsActivePage />
            },
            {
                path: '/patientsActive/',
                element: <PatientsActivePage />
            },
            {
                path: '/patientsInactive/',
                element: <PatientsInactivePage />
            },
            {
                path: '/patientsSearch/',
                element: <PatientsSearchPage />
            },
            {
                path: '/patient-creation',
                element: <PatientCreationPage />
            },
            {
                path: '/patients/:id_patient/timeline/:date/:time?',
                element: <PatientTimelinePage />
            },
            {
                path: '/patients/:id_patient/timeline',
                element: <PatientTimelineRedirectPage />
            },
            {
                path: '/patients/:id_patient/healthReport/:view?',
                element: <HealthReportLastAvailableNightPage />
            },
            {
                path: '/patients/:id_patient/healthReport/last7days/:view?',
                element: <HealthReportLast7DaysPage />
            },
            {
                path: '/patients/:id_patient/healthReport/last30Days/:view?',
                element: <HealthReportLast30DaysPage />
            },
            {
                path: '/patients/:id_patient/healthReport/trends',
                element: <HealthReportTrendsPage />
            },
            {
                path: '/patient-update/:id_patient/:returnTo/:returnToDate?',
                element: <PatientUpdatePage />
            },
            {
                path: '/profile',
                element: <HcpUpdatePage />
            },
            {
                path: '/groups',
                element: <GroupsPage />
            },
            {
                path: '/changelog',
                element: <ChangelogPage />
            },
        ]
    }
];

export default routes;
