import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import PatientUpdateOnboarding from '@/components/business/analytics/patient/patient-update/patient-update-onboarding';
import {Button} from '@/components/buttons/button';
import {InfoMessage} from '@/components/form/info-message';
import {GenderEnumLabel} from '@/enum/gender-enum';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import {colorPalette, zIndices} from '@/themes/darkmode';
import {formatApiDateUniversal} from '@/util/apidate-util';
import {formatPhoneNumberStr} from '@/util/phonenumber-util';

type PatientSummaryProps = {
    patient: PatientTransformed;
    currentPage: { page: string, date: string };
};

const PatientSummary = ({patient, currentPage}: PatientSummaryProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showPatientExtendedData, setShowPatientExtendedData] = useState(false);
    const [showUpdateOnboarding, setShowUpdateOnboarding] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleDocumentClick, { capture: true });
        };
    }, [showPatientExtendedData]);
    const handleDocumentClick = (e: MouseEvent) => {
        if (showPatientExtendedData) {
            const isTargetNotPopupMenu = !(e.target instanceof HTMLElement) || !(ref.current?.contains(e.target) ?? false);
            if (isTargetNotPopupMenu) {
                setShowPatientExtendedData(false);
            }
        }
    };

    // Initialize patient flags
    const isDemoPatient = (patient.patientId?.id ?? '') === 'demo';
    const isStudyPatient = patient.trialId?.id;

    const caregiverData = patient.caregiverInformation;

    const handleClickExtendPatient = () => {
        setShowPatientExtendedData(!showPatientExtendedData);
    };

    const handleClickUpdateOnboarding = () => {
        setShowUpdateOnboarding(true);
    };

    const handleCloseUpdateOnboarding = () => {
        setShowUpdateOnboarding(false);
    };

    const handleClickEditPatient = () => {
        let returnToDate = '';
        if(currentPage.page === 'timeline') {
            returnToDate = currentPage.date;
        }

        const url = `/patient-update/${patient.patientId?.id ?? ''}/${currentPage.page}/${returnToDate}`;

        navigate(url);
    };

    return (
        <StyledWrapper>
            <div className="patient-pic">
                <PatientAvatar skinType={patient.skinType} />
            </div>

            <div className="patient-info">
                <span className="patient-name-cropped">
                    {patient.displayName}
                </span>
                {patient.birthDate && <>{formatApiDateUniversal(patient.birthDate)}</>}
                {patient.sex !== 0 && <span className="patient-sex">{t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}</span>}
                <span className="patient-trial-id">{patient.trialId?.id}</span>
            </div>

            {(!isStudyPatient && !isDemoPatient) && (
                <div className="patient-extend-data-action">
                    <Button className="button-short-extend" displayIcon={true} onClick={handleClickExtendPatient}>{t('patient.extend') ?? 'Extend'}</Button>
                </div>
            )}

            {(!isStudyPatient && !isDemoPatient && showPatientExtendedData) && (
                <div className="patient-extended-data" ref={ref}>
                    <div className="patient-minimize-data-action">
                        <Button className="button-short-minimize" displayIcon={true} onClick={handleClickExtendPatient}>{t('patient.minimize') ?? 'Minimize'}</Button>
                    </div>

                    <div className="patient-pic">
                        <PatientAvatar skinType={patient.skinType} />
                    </div>

                    <div className="patient-info">
                        <span className="patient-name">
                            {patient.displayName}
                        </span>
                        {patient.birthDate && <>{formatApiDateUniversal(patient.birthDate)}</>}
                        <span className="patient-sex">{t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}</span>
                        <span className="patient-trial-id">{patient.trialId?.id}</span>
                    </div>

                    <div className="patient-caregiver">
                        {caregiverData &&
                            <div>
                                <h3>{t('caregiver.title') ?? 'Caregiver'}</h3>
                                <span>{caregiverData.firstName}&nbsp;{caregiverData.lastName}</span>
                                {' '}{caregiverData.gender ? `(${t('global.gender.' + GenderEnumLabel[caregiverData.gender])})` : ''}
                                <br />
                                <span>{formatPhoneNumberStr(caregiverData.phoneNumber)}</span><br />
                                <span>{caregiverData.email}</span>
                            </div>
                        }
                        {!caregiverData &&
                            <InfoMessage infoMessage={t('caregiver.provideInfo') ?? 'Please update patient to provide caregiver information.'} />
                        }
                    </div>

                    {showUpdateOnboarding && (
                        <PatientUpdateOnboarding patientId={patient.patientId?.id ?? ''} showModalOnboarding={true} onClosed={handleCloseUpdateOnboarding} />
                    )}

                    <div className="patient-update-actions">
                        <Button className="button-edit" onClick={handleClickEditPatient}>{t('patient.update') ?? 'Update patient information'}</Button>
                        {caregiverData &&
                            <Button className="button-active" onClick={handleClickUpdateOnboarding}>{t('patient.onboardingInfo') ?? 'Onboarding information'}</Button>
                        }
                    </div>
                </div>
            )}
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
    display:flex;
    width: 200px;
    margin: 0 auto;
    margin-top:10px;
    text-align: left;
    
    .patient-caregiver h3 {
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 15px;
        text-transform: uppercase;
    }
    
    .patient-pic {
        width:60px;
        margin-right:15px;
        display:inline-block;
        vertical-align: top;
    }
    .patient-info {
        display:inline-block;
        vertical-align: top;
        width: 175px;
        span {
            display:block;
            &.patient-name-cropped {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 150px;
                overflow: hidden;
            }
            &.patient-sex {
                font-size:12px;
                color:${colorPalette.clearColor};
            }
            &.patient-birth-date {
                font-size:12px;
            }
            &.patient-trial-id {
                font-size:12px;
                color:${colorPalette.clearColor};
            }
        }
    }
    
    .patient-extend-data-action {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
    
    .patient-extended-data {
        position: absolute;
        top: 3px;
        left: 0;
        padding: 20px;
        background: ${colorPalette.thirdBackground};
        width: 325px;
        z-index: ${zIndices.overlays};
        border-radius:0 5px 5px 0;

        .patient-info {
          display: inline-block;
          vertical-align: top;
          padding-top: 7px;

          span {
            &.patient-name {
              word-break: break-word;
            }
          }
        }
        
        .patient-caregiver {
            margin:15px 0;
            border-top:1px solid ${colorPalette.secondBackground};
            padding:10px;
        }

        .patient-minimize-data-action {
           position: absolute;
           top: 8px;
           right: 8px;
        }
        
        .patient-update-actions {
            border-top:1px solid ${colorPalette.secondBackground};
            padding-top:20px;
            margin-top: 15px;
            button {
                margin-bottom:10px;
            }
        }
    }
}
`;

export {PatientSummary};
