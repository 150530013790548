import {JSX} from 'react';
import Styled from 'styled-components';

import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation';
import {PatientsInactive} from '@/components/business/analytics/patients/patients-inactive';
import ErrorBoundary from '@/components/errors/error-boundary';
import {Page} from '@/decorators/page';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum';

function wrapWithPage(Component: JSX.ElementType) {
    return Page({
        name: 'Inactive patients'
    })(Component);
}

type PatientsInactivePageProps = {
    className?: string;
};
function PatientsInactivePageUnstyled(props: PatientsInactivePageProps) {
    return (
        <div className={`patients-inactive-page ${props.className ?? ''}`}>
            <ErrorBoundary>
                <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.INACTIVE} />

                <PatientsInactive />
            </ErrorBoundary>
        </div>
    );
}

//language=SCSS
const PatientsInactivePage = wrapWithPage(Styled(PatientsInactivePageUnstyled)`
& {
    min-height: 500px;
    width: 95%;
    max-width: 1700px;
    height: calc(100% - 80px);
    margin: 30px auto 0 auto;
}
`);

export {PatientsInactivePage};

