import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientsSearchForm from '@/components/business/analytics/patients/patients-search-form';
import {PatientsSearchTable} from '@/components/business/analytics/patients/patients-search-table';
import LoadingView from '@/components/static/loading-view';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import {transformPatients} from '@/model/transformers/transform-patients';
import searchBabyHello from '@/public/images/search-baby-hello.svg';
import {colorPalette} from '@/themes/darkmode';

const PatientsSearch = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');

    const [patientsLoading, patients] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatients,
        data: {},
        memoize: false,
        noRefresh: true,
        transformer: (patients) => {
            const patientsTransformed = transformPatients(patients);
            patientsTransformed.patient.sort((a, b) => {
                return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
            });
            return patientsTransformed;
        }
    });

    const loading = patientsLoading || !patients;

    let patientsSearchData: PatientTransformed[] = [];
    if (!loading) {
        if (search !== '') {
            const searchValues = search.toLowerCase().split(' ');
            let tempPatientsSearchData: PatientTransformed[] = [...patients.patient];

            searchValues.forEach(searchItem => {
                tempPatientsSearchData = tempPatientsSearchData.filter(patient => {
                    return patient.firstName?.toLowerCase().includes(searchItem) || patient.lastName?.toLowerCase().includes(searchItem);
                });
            });

            patientsSearchData = tempPatientsSearchData;
        }
        else {
            patientsSearchData = [...patients.patient];
        }
    }

    const handleChangeSearchForm = (search: string) => {
        setSearch(search);
    };

    return (
        <StyledWrapper>
            <div className="searchBlock">
                <div>
                    <img src={searchBabyHello} width={100} alt="" />
                </div>
                <div className="searchText">
                    <h2>{t(['Search patient', 'pages.patients.searchPatient'])}</h2>
                    <h3>{t(['You can search for a patient first name or last name.', 'pages.patients.searchPatientText'])}</h3>
                </div>
                <div className="searchActions">
                    {loading ?
                        <LoadingView/>
                        :
                        <PatientsSearchForm onChanged={handleChangeSearchForm}/>
                    }
                </div>
            </div>

            {!loading && patientsSearchData &&
                <PatientsSearchTable patients={patientsSearchData} />
            }
            {loading && <LoadingView/> }
        </StyledWrapper>
    );
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    padding-bottom: 50px;
    
    .searchBlock {
        width: 100%;
        background: ${colorPalette.thirdBackground};
        border-radius: 10px;
        padding: 30px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-bottom: 30px;
        
        .searchText {
            margin-left: 20px;
            
            h2 {
                font-size: 16px;
            }
            
            h3 {
                font-size: 14px;
            }
        }
        
        .searchActions {
            margin-left: auto;
            display: flex;
        }
    }
}
`;

export default PatientsSearch;
