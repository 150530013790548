import {HcpQueryServiceClient} from 'gabi-api-ts/v2/hcp/query/hcp_query.client';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';
import {JSX, PropsWithChildren, useState} from 'react';
import Styled from 'styled-components';

import GuidedTourPatient from '@/components/business/analytics/guidedTour/guided-tour-patient';
import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import {PatientsActiveTable} from '@/components/business/analytics/patients/patients-active-table';
import PatientsDashboardNavigation from '@/components/business/analytics/patients/patients-dashboard-navigation';
import PatientsWelcomeSection from '@/components/business/analytics/patients/patients-welcome-section';
import ErrorBoundary from '@/components/errors/error-boundary';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {Page} from '@/decorators/page';
import {PatientDashboardTypeEnum} from '@/enum/patient-dashboard-type-enum';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {transformPatient} from '@/model/transformers/transform-patient';
import {transformPatients} from '@/model/transformers/transform-patients';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';
import {useTutorialStatus} from '@/services/tutorial-service';
import {apiDateToday, subDays} from '@/util/apidate-util';

function wrapWithPage(Component: JSX.ElementType) {
    return Page({
        name: 'Active patients'
    })(Component);
}

type PatientsActivePageProps = {
    className?: string;
};

function getPatientsActivePageViewModel() {
    const dateTo = apiDateToday();
    const dateFrom = subDays(dateTo, 7);
    return {
        dateFrom: dateFrom,
        dateTo: dateTo,
    };
}
export const testables = {
    getPatientsActivePageViewModel,
};

function PatientsActivePageUnstyled(props: PatientsActivePageProps) {
    const [programTypeOverride, setProgramTypeOverride] = useState(null);
    const tutorialStatus = useTutorialStatus();

    const viewModel = getPatientsActivePageViewModel();

    const [programTypeLoading, programType, programTypeError] = useBackendQuery({
        serviceClient: HcpQueryServiceClient,
        query: HcpQueryServiceClient.prototype.getProgramType,
        data: {},
        memoize: false,
    });

    const [activePatientsLoading, activePatients] = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getPatientsWithRecording,
        data: {
            from: viewModel.dateFrom,
            to: viewModel.dateTo,
        },
        memoize: false,
        transformer: (patients) => {
            const patientsTransformed = transformPatients(patients);
            patientsTransformed.patient.sort((a, b) => {
                const aStr = a.lastRecordingDataTime ? formatApiFullDateToJSDate(a.lastRecordingDataTime).toISOString() : '2000-01-01';
                const bStr = b.lastRecordingDataTime ? formatApiFullDateToJSDate(b.lastRecordingDataTime).toISOString() : '2000-01-01';
                return bStr.localeCompare(aStr);
            });
            return patientsTransformed;
        },
    });

    const loading = (
        (programTypeLoading) ||
        (activePatientsLoading || !activePatients)
    );

    const Wrapper = ({className, children}: PropsWithChildren<{className?: string}>) => (
        <div className={`patients-active-page ${className ?? ''}`}>
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </div>
    );

    if (loading) {
        return (
            <Wrapper className={props.className}>
                <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.RECENT_RECORDING}/>
                <LoadingView/>
            </Wrapper>
        );
    }
    else if (programTypeError) {
        return (
            <Wrapper className={props.className}>
                <ComponentErrorMessage component="PatientsActive"/>
            </Wrapper>
        );
    }
    else {
        const programTypeToShow = programTypeOverride !== null ? programTypeOverride : programType?.programType;
        const patients = [ ...activePatients.patient ];
        if (tutorialStatus.demoPatient) {
            patients.unshift(transformPatient(DemoPatient.patient));
        }

        return (
            <Wrapper className={props.className}>
                {tutorialStatus.guidedTourPatient && (
                    <GuidedTourPatient />
                )}
                <PatientsDashboardNavigation activeDashboard={PatientDashboardTypeEnum.RECENT_RECORDING}/>

                {programTypeToShow !== null && programTypeToShow !== undefined && (
                    <div>
                        <PatientsWelcomeSection program={programTypeToShow as number} onChanged={setProgramTypeOverride}/>
                        <PatientsActiveTable
                            patients={patients}
                            program={programTypeToShow}
                        />
                    </div>
                )}
            </Wrapper>
        );
    }
}

//language=SCSS
const PatientsActivePage = Styled(wrapWithPage(PatientsActivePageUnstyled))`
& {
    min-height: 500px;
    width: 95%;
    max-width: 1700px;
    height: calc(100% - 80px);
    margin: 30px auto 150px auto;
    padding-bottom: 50px;
}
`;

export {PatientsActivePage};
