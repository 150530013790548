import {LocalStorage} from '@/util/local-storage';
import {useSyncExternalStore} from 'react';

import {GabiEnv, GabiEnvAcceptance, GabiEnvData, GabiEnvDevelopment, GabiEnvProduction} from '@/model/gabi-env';
import {LocalStorageFieldName} from '@/model/local-storage-field';
import {ObservableVar} from '@/util/observable-var';

const defaultGabiEnv = (__GABI_ENV__ === 'development') ? GabiEnv.development : GabiEnv.production;

class GabiEnvService_ {
    readonly selectedEnv: ObservableVar<GabiEnv> = new ObservableVar(GabiEnv.not_set as GabiEnv);

    async init() {
        const selectedEnvStr = await LocalStorage.get(LocalStorageFieldName.SELECTED_ENVIRONMENT, null);
        this.selectedEnv.set((() => {
            switch (selectedEnvStr) {
            case GabiEnv.production:
                return GabiEnv.production;
            case GabiEnv.acceptance:
                return GabiEnv.acceptance;
            case GabiEnv.development:
                return GabiEnv.development;
            default: {
                const hostPrefix = location.hostname.split('.')[0];
                if (hostPrefix === 'dev' || hostPrefix === 'localhost' || hostPrefix === '127') {
                    return GabiEnv.development;
                }
                else if (hostPrefix === 'acceptance') {
                    return GabiEnv.acceptance;
                }
                else {
                    return GabiEnv.production;
                }
            }
            }
        })());
        console.log(`ENV: ${this.selectedEnv.value}`);
    }

    getEnvironment(): GabiEnvData {
        return this.getEnvironment_(this.selectedEnv.value);
    }

    private getEnvironment_(env: GabiEnv): GabiEnvData {
        switch (env) {
        case GabiEnv.production:
            return GabiEnvProduction;
        case GabiEnv.acceptance:
            return GabiEnvAcceptance;
        case GabiEnv.development:
            return GabiEnvDevelopment;
        default:
            return this.getEnvironment_(defaultGabiEnv);
        }
    }
}

const GabiEnvService = new GabiEnvService_();

function useGabiEnv() {
    return useSyncExternalStore<GabiEnv>(
        GabiEnvService.selectedEnv.onChange,
        () => GabiEnvService.selectedEnv.value,
    );
}

export {GabiEnvService, useGabiEnv};
