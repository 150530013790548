import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';

export type PatientTransformed = Patient & {
    displayName: string;
    lastRecordingDataTimeSortable: number;
};

export function transformPatient(patient: Patient): PatientTransformed {
    const displayName = `${patient.lastName && patient.lastName !== '' ? patient.lastName : (patient.externalSource?.idInSource ?? '')}${patient.firstName && patient.firstName !== '' ? ', ' : ''}${patient.firstName ?? ''}`;

    let lastRecordingDataTimeSortable = 10E15;
    if (patient.lastRecordingDataTime && patient.lastRecordingDataTime.date && patient.lastRecordingDataTime.time) {
        const lastRecordingDateTime = new Date(
            patient.lastRecordingDataTime.date.year,
            patient.lastRecordingDataTime.date.month - 1,
            patient.lastRecordingDataTime.date.day,
            patient.lastRecordingDataTime.time.hour,
            patient.lastRecordingDataTime.time.minutes,
            patient.lastRecordingDataTime.time.seconds
        );
        lastRecordingDataTimeSortable = lastRecordingDateTime.getTime();
    }

    return {
        ...patient,
        displayName: displayName,
        lastRecordingDataTimeSortable: lastRecordingDataTimeSortable,
    };
}
