import {AlertType, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {AverageCell} from '@/components/business/analytics/patient-overview-cells/average-cell';
import {EventsCell} from '@/components/business/analytics/patient-overview-cells/events-cell';
import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar';
import {PatientLastRecording} from '@/components/business/analytics/patient/common/patient-last-recording';
import {HcpProgramConfig} from '@/components/business/analytics/patients/hcp-program-config';
import {Button} from '@/components/buttons/button';
import {PatientChildSexLabel} from '@/enum/patient-child-sex-enum';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import {TutorialService} from '@/services/tutorial-service';
import {colorPalette, medias} from '@/themes/darkmode';
import {formatApiDateUniversal} from '@/util/apidate-util';
import {formatDateForUrl} from '@/util/url-util';

import '@/stylesheets/bootstrap.min.css';

type PatientsActiveTableRowProps = {
    className?: string;
    data: PatientTransformed;
    programConfig: HcpProgramConfig;
};

function PatientsActiveTableRow({className, data, programConfig}: PatientsActiveTableRowProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const patient = data;

    const handleClickHideDemoPatient = (e: Event) => {
        e.preventDefault();
        TutorialService.updateDemoPatientEnabled(false);
    };

    const handleWidgetChartClick = (selectedDate: Date) => {
        const url = `/patients/${patient.patientId!.id}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    };

    return (
        <StyledWrapper className={`patients-active-table-row ${className ?? ''}`}>
            <td className="linkCell">
                <Link to={`/patients/${patient.patientId!.id}/healthReport`}>{t('pages.patients.patientDetails') ?? 'Patient details'}</Link>
            </td>
            <td className="patientNameCell">
                <div>
                    <PatientAvatar skinType={patient.skinType} />
                    <p>
                        {patient.displayName}
                        <span>
                            {patient.birthDate ?
                                <>{formatApiDateUniversal(patient.birthDate)}&nbsp;-&nbsp;</>
                                :
                                <><em>{t('patient.birthDateEmpty') ?? 'No birth date information'}&nbsp;-&nbsp;</em></>
                            }
                            {t('patient.sexLabel.' + PatientChildSexLabel[patient.sex])}
                        </span>
                        <span>{patient.trialId?.id}</span>
                    </p>
                    {patient.patientId!.id === 'demo' && (
                        <Button
                            className="button-hide-filled-hover"
                            displayIcon={true}
                            onClick={handleClickHideDemoPatient}
                        >
                            {t('pages.patients.hide') ?? 'Hide'}
                        </Button>
                    )}
                </div>
            </td>
            <td className="lastDataCell">
                <PatientLastRecording lastRecordingDataTime={patient.lastRecordingDataTime} />
            </td>

            {programConfig.pulseRate.average && (
                <AverageCell patient={patient} signalType={SignalType.Signal_PR} handleWidgetChartClick={handleWidgetChartClick} />
            )}

            {programConfig.spo2.average && (
                <AverageCell patient={patient} signalType={SignalType.Signal_SPO2}
                    handleWidgetChartClick={handleWidgetChartClick}
                />
            )}

            {programConfig.pulseRate.eventsBelow && (
                <EventsCell alertType={AlertType.Alert_LOW} patient={patient} signalType={SignalType.Signal_PR}
                    handleWidgetChartClick={handleWidgetChartClick} unit={t('global.eventsPerHour')}
                />
            )}

            {programConfig.pulseRate.eventsAbove && (
                <EventsCell alertType={AlertType.Alert_HIGH} patient={patient} signalType={SignalType.Signal_PR}
                    handleWidgetChartClick={handleWidgetChartClick} unit={t('global.eventsPerHour')}
                />
            )}

            {programConfig.spo2.eventsBelow && (
                <EventsCell alertType={AlertType.Alert_LOW} patient={patient} signalType={SignalType.Signal_SPO2}
                    handleWidgetChartClick={handleWidgetChartClick} unit={'%'}
                />
            )}
        </StyledWrapper>
    );
}

//language=SCSS
const StyledWrapper = styled.tr`
& {
    position: relative;

    td {
        height: 70px;
        width: 160px;
        @media screen and (${medias.lgMax}) {
            padding-left: 10px !important;
            padding-right: 10px !important;
            height: 90px;
        }
        
        &.linkCell {
            width: 80px;
            @media screen and (${medias.lgMax}) {
                width: 60px;
            }
        }

        &.lastDataCell {
            width: 200px;
        }
        
        &.patientNameCell {
            width: 320px;
            @media screen and (${medias.lgMax}) {
                width: 250px;
            }
            p {
                flex-grow: 3;
            }
            .button-hide-filled-hover {
                margin-left: 15px;
                text-align: left;
                background-color ${colorPalette.clearColor};

                @media screen and (${medias.lgMax}) {
                    width: 0;
                    margin-left: 8px;
                    padding-left: 25px;
                    overflow: hidden;
                    text-indent: 50px;
                }
            }
        }
        
        .events-cell__nb-events {
            text-wrap: nowrap;
        }
    }
    
    > td:nth-child(1 of .hoverableCell) {
        border-left-width: 5px;
    }
    
    .noDataTooltip {
        top: 8px;
        right: 8px;
    }
    
    .warning-overlay {
        background: ${colorPalette.thirdBackground};
        margin-left: 20px;
        border-radius: 5px;
        z-index: 1050;
        position: absolute;
        width: 500px;
        right: 0;
        top:55px;
        box-shadow: 0 1px 3px ${colorPalette.darkColor};
    
        .trendsNoData {
            font-style: italic;
            margin-top:20px;
        }
        
        .average-block {
            text-align:right;
            position: relative;
            font-size: 13px;
            
            p {
                margin-bottom: 5px;
                span {
                    display:inline;
                }
            }
        }
    }
}
`;

export {PatientsActiveTableRow};
