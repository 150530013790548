import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

class SmallScreenView extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <p className="info-message">{t(['Gabi Analytics is not made for smartphones and small tablets. Please open this app on a wider screen.', 'smallScreenView.title'])}</p>
                <img src="/public/images/small-screen-background.svg" alt={t(['Computer on a desk with Gabi logo on the screen.', 'smallScreenView.alt'])} />
            </div>
        );
    }
}
//language=SCSS
SmallScreenView = Styled(SmallScreenView)`
& {
    height:100vh;
    padding: 50px;
    text-align:center;
    
    p.info-message {
        text-align:left;
        width:100%;
        max-width: 500px;
        margin:0 auto;
    }
    
    img {
        width:100%;
        max-width:500px;
        margin-top:25px;
    }
}
`;

export default withTranslation()(SmallScreenView);
