import {HealthCondition} from 'gabi-api-ts/v2/patient/query/patient_query';
import {PatientQueryServiceClient} from 'gabi-api-ts/v2/patient/query/patient_query.client';

import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import {useBackendQuery, useBackendQueryResult} from '@/hooks/use-backend-query';
import {NoExtraProp} from '@/types/no-extra-prop';

export type usePatientDataArgs = {
    patientId: string;
    memoize?: boolean;
    disabled?: boolean;
};

function usePatientHealthCondition(options: NoExtraProp<usePatientDataArgs>, dependencies?: unknown[]) : useBackendQueryResult<HealthCondition> {
    const isDemoPatient = options.patientId === 'demo';
    const out = useBackendQuery({
        serviceClient: PatientQueryServiceClient,
        query: PatientQueryServiceClient.prototype.getHealthCondition,
        memoize: options.memoize ?? false,
        disabled: isDemoPatient || options.disabled,
        data: { id: options.patientId },
    }, dependencies);

    if (isDemoPatient) {
        return [
            false,
            DemoPatient.healthCondition,
            null,
            out[3],
        ];
    }
    else {
        return out;
    }
}

export { usePatientHealthCondition };
