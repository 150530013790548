import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import PatientsTableRow from '@/components/business/analytics/patients/patients-table-row';
import {InfoMessage} from '@/components/form/info-message';
import {TableSortable} from '@/components/table/table-sortable';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import searchBabyNoResult from '@/public/images/search-baby-no-result.svg';
import {formatApiFullDateToJSDate} from '@/services/api-requests/requests-utils';

type PatientsSearchTableProps = {
    patients: PatientTransformed[];
};
function PatientsSearchTable({patients}: PatientsSearchTableProps) {
    const { t } = useTranslation();

    const tableSortableHeaderColumn = [
        {
            id: 'patientId.id',
            label: '',
            sortable: false,
        },
        {
            id: 'lastName',
            label: t('patient.title') ?? 'Patient',
            compareFn: (a: PatientTransformed, b: PatientTransformed) => {
                return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
            },
            sortable: true,
        },
        {
            id: 'lastRecordingDataTimeSortable',
            label: t('patient.lastRecording') ?? 'Last recording',
            compareFn: (a: PatientTransformed, b: PatientTransformed) => {
                const aStr = a.lastRecordingDataTime ? formatApiFullDateToJSDate(a.lastRecordingDataTime).toISOString() : '2000-01-01';
                const bStr = b.lastRecordingDataTime ? formatApiFullDateToJSDate(b.lastRecordingDataTime).toISOString() : '2000-01-01';
                return aStr.localeCompare(bStr);
            },
            sortable: true,
        },
        {
            id: 'caregiver',
            label: t('caregiver.title') ?? 'Caregiver',
            sortable: false,
        },
        {
            id: 'edit',
            label: '',
            sortable: false,
        },
        {
            id: 'delete',
            label: '',
            sortable: false,
        },
    ];

    if (patients) {
        if (patients.length > 0) {
            return (
                <TableSortable
                    data={patients}
                    keyFn={item => `${item.patientId?.id ?? ''}`}
                    columns={tableSortableHeaderColumn}
                    defaultSorting={{id: 'lastName', sortByOrder: 'asc'}}
                    TableBodyRowComponent={PatientsTableRow}
                />
            );
        }
        else {
            return (
                <StyledWrapperNoResult>
                    <img src={searchBabyNoResult} width={150} alt="baby fox" />
                    <InfoMessage infoMessage={t('pages.patients.searchPatientNoResult') ?? 'No results for this search.'} />
                </StyledWrapperNoResult>
            );
        }
    }
    else {
        return <ComponentErrorMessage component="PatientsSearchTable" />;
    }
}

//language=SCSS
const StyledWrapperNoResult = styled.div`
& {
    width: 400px;
    margin: 0 auto;
    text-align: center;
    
    img {
        margin-bottom: 20px;
    }
}
`;

export {PatientsSearchTable};
