import {addDays} from 'date-fns';
import {SignalEventConfiguration} from 'gabi-api-ts/v2/signal/common/signal_common.ts';
import type {Date as ApiDate} from 'gabi-api-ts/v2/signal/query/signal_query';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import {EventConfigurationButton} from '@/components/business/analytics/common/event-configuration-button';
import {PatientSummary} from '@/components/business/analytics/patient/common/patient-summary';
import {PatientHealthCondition} from '@/components/business/analytics/patient/patient-health-condition/patient-health-condition';
import {PopupMenu} from '@/components/buttons/popup-menu';
import LoadingView from '@/components/static/loading-view';
import ComponentErrorMessage from '@/components/widgets/component-error-message';
import {PatientTransformed} from '@/model/transformers/transform-patient';
import calendarIcon from '@/public/icons/calendar-icon.svg';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils';
import {colorPalette, medias, zIndices} from '@/themes/darkmode';
import {formatDateUniversal} from '@/util/date-utils';
import {NewDateUtil} from '@/util/new-date-util';
import {formatDateForUrl} from '@/util/url-util';

type TimelineSidebarProps = {
    patient?: PatientTransformed;
    eventConfiguration: SignalEventConfiguration;
    daysWithData: ApiDate[];
    selectedDate: Date;
    onDateSelected: (date: Date | null) => void;
};

const TimelineSidebar = ({patient, daysWithData, selectedDate, onDateSelected}: TimelineSidebarProps) => {
    const patientId = patient?.patientId?.id ?? '';

    const currentDate = NewDateUtil();

    if (patientId) {
        return (
            <StyledWrapper>
                <div className="timeline-sidebar">
                    <div className="patient-summary-wrapper">
                        {!patient ?
                            <LoadingView />
                            :
                            <PatientSummary
                                patient={patient}
                                currentPage={{page: 'timeline', date: formatDateForUrl(selectedDate)}}
                            />
                        }
                    </div>
                    <div className="patient-health-condition-wrapper">
                        {!patient ?
                            <LoadingView/>
                            :
                            <PatientHealthCondition
                                patient={patient}
                            />
                        }
                    </div>
                    <div className="timeline-date-selection-wrapper">
                        <PopupMenu className="popupMenu-calendar" buttonText={(<>
                            {formatDateUniversal(selectedDate)} <br/>
                            {formatDateUniversal(addDays(selectedDate, 1))}
                        </>)}>
                            <DatePicker className="form-control"
                                selected={selectedDate}
                                maxDate={currentDate}
                                highlightDates={daysWithData.map(formatApiDateToJSDate)}
                                onChange={onDateSelected}
                                inline
                                dateFormat="dd-MMM-yyyy"
                            />
                        </PopupMenu>
                    </div>
                    <div className="timeline-event-configuration">
                        <EventConfigurationButton patientId={patient?.patientId?.id ?? ''} />
                    </div>
                </div>
            </StyledWrapper>
        );
    }
    else {
        return <ComponentErrorMessage component="TimelineSidebar" />;
    }
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    .timeline-sidebar {
        background: ${colorPalette.secondBackground};
        position: fixed;
        height: 100%;
        width: 250px;
        border-right: 1px solid ${colorPalette.darkColor};
        z-index: ${zIndices.sidebar};
        
        div.patient-summary-wrapper,
        div.patient-health-condition-wrapper {
            position: relative;
        }
        
        > div {
            border-bottom: 1px solid ${colorPalette.darkColor};
            padding: 20px;
        }
        
        div.timeline-date-selection-wrapper {
            text-align: center;
        }
        
        .popup-menu-button {
            text-indent: -10000px;
            color: transparent;
            padding-left: 28px;
            font-size: 12px;
            
            @media screen and (${medias.lgMin}) {
                text-indent: initial;
                color: inherit;
                padding-left: 33px;
                padding-right: 10px;
            }
        }
        
        .popupMenu-calendar {
            width: 85%;
        }
        
        .popupMenu-calendar .react-datepicker {
            background: inherit;
            border: none;
            
            .react-datepicker__header {
                background-color: inherit;
            }
        }
        
        .popupMenu-calendar .popup-menu-button {
            background-image: url(${calendarIcon});
            background-repeat: no-repeat;
            background-position: 14px center;
            background-size: 18px;
            width: 100%;
        }
        
        .timeline-event-configuration {
            .button-event-configuration {
                border-radius: 5px;
                right: auto;
                top: auto;
                position: relative;
                margin: 0 auto;
                display: block;
            }
        }
    }
}
`;

export {TimelineSidebar};
