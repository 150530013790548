import {Patient} from 'gabi-api-ts/v2/patient/query/patient_query';
import {AlertType, SignalType} from 'gabi-api-ts/v2/signal/query/signal_query';
import {SignalQueryServiceClient} from 'gabi-api-ts/v2/signal/query/signal_query.client';
import {JSX} from 'react';
import {getI18n, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Styled from 'styled-components';

import {HealthReportDuration} from '@/components/business/analytics/health-report/health-report-duration';
import {HealthReportEventsPerDay} from '@/components/business/analytics/health-report/health-report-events-per-day';
import {HealthReportNoRecording} from '@/components/business/analytics/health-report/health-report-no-recording';
import {HealthReportPercentagePerDay} from '@/components/business/analytics/health-report/health-report-percentage-per-day';
import {HealthReportStatisticsPerDay} from '@/components/business/analytics/health-report/health-report-statistics-per-day';
import {HealthReportWrapper} from '@/components/business/analytics/health-report/health-report-wrapper';
import ColoredContainer from '@/components/layout/colored-container';
import LoadingView from '@/components/static/loading-view';
import {Page} from '@/decorators/page';
import withRouter from '@/decorators/withRouter';
import {HealthReportDashboardTypeEnum} from '@/enum/health-report-dashboard-type-enum';
import {usePatientData} from '@/hooks/backend/use-patient-data';
import {useBackendQuery} from '@/hooks/use-backend-query';
import {formatApiDateToJSDate} from '@/services/api-requests/requests-utils-ts';
import {colorPalette} from '@/themes/darkmode';
import {addDays, apiDateToday, isApiDateInInterval, subDays} from '@/util/apidate-util';
import {noop} from '@/util/noop';
import {formatDateForUrl} from '@/util/url-util';

function getPagePath() {
    return [
        {
            route: 'patients',
            name: 'Patients'
        },
    ];
}

function wrapWithPage(Component: JSX.ElementType) {
    return Page({
        name: (props: { [key: string]: unknown }) => getI18n().t('healthReport.lastNnights.title', {N: props.nbDays}),
        pagePath: getPagePath,
    })(Component);
}

function getLastNDaysViewModel(patientData: Patient, nbDays: number) {
    const todayAtMidnight = apiDateToday();
    const tomorrowAtMidnight = addDays(todayAtMidnight, 1);
    const lastRecordingDate = patientData.lastRecordingDate ?? { year: 2020, month: 1, day: 1 };
    const dateFrom = subDays(todayAtMidnight, nbDays);
    const dateTo = todayAtMidnight;

    const dashboardHasContent = isApiDateInInterval(lastRecordingDate ?? todayAtMidnight, dateFrom, tomorrowAtMidnight);

    return {
        dashboardHasContent: dashboardHasContent,
        dateFrom: dateFrom,
        dateTo: dateTo,
        lastRecordingDate: lastRecordingDate,
    };
}
export const testables = {
    getLastNDaysViewModel,
};

function getHealthReportDashboardTypeEnum(nbDays: number) {
    if (nbDays === 7) {
        return HealthReportDashboardTypeEnum.LAST_7DAYS;
    }
    else if (nbDays === 30) {
        return HealthReportDashboardTypeEnum.LAST_30DAYS;
    }
    else {
        throw new Error(`Invald nbDays: ${nbDays}`);
    }
}

type HealthReportLastNDaysPageProps = {
    nbDays: number,
    className?: string,
    params?: {id_patient: string},
};

function HealthReportLastNDaysPage(props: HealthReportLastNDaysPageProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const patientId = props.params?.id_patient ?? '';

    const [patientDataLoading, patientData] = usePatientData({
        patientId: (props as {params: {id_patient: string}}).params.id_patient,
        memoize: true,
    });

    const [signalsEventConfigurationLoading, signalsEventConfiguration] = useBackendQuery({
        serviceClient: SignalQueryServiceClient,
        query: SignalQueryServiceClient.prototype.getEventConfiguration,
        memoize: true,
        data: { patientId: { id: (props as {params: {id_patient: string}}).params.id_patient } },
    });

    const loading = (
        (signalsEventConfigurationLoading || !signalsEventConfiguration) ||
        (patientDataLoading || !patientData)
    );

    const dashboardTypeEnum = getHealthReportDashboardTypeEnum(props.nbDays);

    function handleWidgetChartClick(selectedDate: Date) {
        const url = `/patients/${props.params?.id_patient ?? ''}/timeline/${formatDateForUrl(selectedDate)}`;
        navigate(url);
    }

    if (loading) {
        return (
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={dashboardTypeEnum}
                eventConfiguration={signalsEventConfiguration}
                onChanged={noop}
            >
                <LoadingView />
            </HealthReportWrapper>
        );
    }

    const viewModel = getLastNDaysViewModel(patientData, props.nbDays);

    return (
        <div className={props.className}>
            <HealthReportWrapper
                patientId={patientId}
                patientData={patientData}
                activeDashboard={dashboardTypeEnum}
                eventConfiguration={signalsEventConfiguration}
                onChanged={noop}
            >
                {viewModel.dashboardHasContent &&
                    <div className="health-report-switch-wrapper">
                        <HealthReportDuration dateFrom={viewModel.dateFrom} dateTo={viewModel.dateTo} patientId={patientId}/>
                    </div>
                }

                {viewModel.dashboardHasContent &&
                    <div className={'health-report-content health-report-content-view'}>
                        <div className="health-report-widgets">
                            <ColoredContainer className="widget-average" color={colorPalette.signalType.pulseRate}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    lastRecordingDate={viewModel.lastRecordingDate}
                                    dateFrom={viewModel.dateFrom}
                                    dateTo={viewModel.dateTo}
                                    displayPreviousNightsAverage={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>

                            <ColoredContainer className="widget-events" color={colorPalette.signalType.pulseRate}>
                                <HealthReportEventsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_PR}
                                    alertTypes={[AlertType.Alert_LOW, AlertType.Alert_HIGH]}
                                    dateFrom={formatApiDateToJSDate(viewModel.dateFrom)}
                                    dateTo={formatApiDateToJSDate(viewModel.dateTo)}
                                    subtitle={t('healthReport.events.last24h.subtitle', {duration: signalsEventConfiguration?.configuration?.hr?.sensibility?.seconds ?? 1}) ?? ''}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>

                            <ColoredContainer className="widget-average" color={colorPalette.signalType.spo2}>
                                <HealthReportStatisticsPerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    lastRecordingDate={viewModel.lastRecordingDate}
                                    dateFrom={viewModel.dateFrom}
                                    dateTo={viewModel.dateTo}
                                    displayPreviousNightsAverage={false}
                                    onClick={handleWidgetChartClick}
                                />
                            </ColoredContainer>

                            <ColoredContainer className="widget-events" color={colorPalette.signalType.spo2}>
                                <HealthReportPercentagePerDay
                                    patientId={patientId}
                                    signalType={SignalType.Signal_SPO2}
                                    alertType={AlertType.Alert_LOW}
                                    lastRecordingDate={formatApiDateToJSDate(viewModel.lastRecordingDate)}
                                    dateFrom={formatApiDateToJSDate(viewModel.dateFrom)}
                                    dateTo={formatApiDateToJSDate(viewModel.dateTo)}
                                    onClick={handleWidgetChartClick}
                                    displayPreviousNightsAverage={false}
                                />
                            </ColoredContainer>
                        </div>
                    </div>
                }
                {!viewModel.dashboardHasContent &&
                    <HealthReportNoRecording patient={patientData!} activeDashboard={dashboardTypeEnum} />
                }
            </HealthReportWrapper>
        </div>
    );
}

// @ts-expect-error will change after migration to functionnal
//language=SCSS
HealthReportLastNDaysPage = Styled(HealthReportLastNDaysPage)`
& {
    .health-report-content {
        display: flex;
        flex-wrap: nowrap;
    }

    .health-report-widgets {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        width: 100%;
        margin-top: 20px;
        gap: 10px;
    }
    
    .widget-events {
        position: relative;
        min-height: 340px;
    }

    .widget-average {
        position: relative;
        min-height: 340px;
    }
}
`;

// @ts-expect-error will change after migration to functionnal
HealthReportLastNDaysPage = withRouter(wrapWithPage(HealthReportLastNDaysPage));

export {HealthReportLastNDaysPage};
